import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import { getPurchasesByOrgIdStatus } from '../../../../../services/api/purchases';

import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementFull from '../../../../ui/formComponents/formElementFull/FormElementFull';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import InputText from '../../../../ui/formComponents/inputText/InputText';

const commonInputProps = {
  height: '31px',
  size: 'small' as 'small' | 'big' | undefined
};
const GetPurchasesError = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    organizationId: '',
    errors: [] as ErrorType[]
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleSubmit = async () => {
    try {
      setLoadingButton(true);
      const data = await getPurchasesByOrgIdStatus(formData.organizationId, 'error');
      window.open(data.file_url, '_blank');
      setLoadingButton(false);
    } catch (error) {
      setLoadingButton(false);
      console.error(error);
    }
  };
  return (
    <div className='get-purchases-error'>
      <FormHeader title={t('purchasesUpload.getPurchasesWithErrors')} />
      <FormWrapper>
        <FormElementFull>
          <InputText
            {...commonInputProps}
            icon={'/images/icons/organization.svg'}
            placeholder={t('purchasesUpload.organizationId')}
            label={t('purchasesUpload.organizationId')}
            value={formData.organizationId}
            onChangeValue={onChangeValue('organizationId')}
            error={formData.errors.find((error) => error.error === 'organizationId')}
          />
        </FormElementFull>
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('purchasesUpload.save')}
          onClick={handleSubmit}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default GetPurchasesError;
