import React from 'react';
import './styles.scss';

type TotalElement = {
  value: number;
  label: React.ReactNode;
};
interface Props extends React.HTMLAttributes<HTMLDivElement> {
  totalLabel: React.ReactNode;
  totalElements: TotalElement[];
}
const TotalLegendV2 = ({ totalLabel, totalElements, ...rest }: Props) => {
  return (
    <div className='total-legend-v2' {...rest}>
      <span className='on-light-text-color tag-font'>{totalLabel}</span>
      {totalElements.map((element, index) => (
        <div className='item' key={index}>
          <span className='highlight-text-color tag-bigger-font'>{element.value}</span>
          <span className='on-light-text-color tag-font'>{element.label}</span>
        </div>
      ))}
    </div>
  );
};

export default TotalLegendV2;
