import { useTranslation } from 'react-i18next';
import ErrorLabel from '../statusLabels/errorLabel/ErrorLabel';
import { hasKey } from '../../../utils/objects';
import { STATUS_ICON_MAP, TAG } from './constants';
import { IconName } from '../../../types/utils/iconName';

type Props = {
  status: keyof typeof TAG;
  icon?: IconName;
  text?: string;
};

export const StatusTag: React.FC<Props> = ({ status, text, icon }) => {
  const Component = TAG[status] ?? ErrorLabel;
  const { t } = useTranslation();

  const defaultIcon = hasKey(STATUS_ICON_MAP, status) ? STATUS_ICON_MAP[status] : undefined;

  const labelIcon = icon ?? defaultIcon;

  if (!text) {
    return <Component icon={labelIcon} />;
  }

  return <Component icon={labelIcon}>{t(text)}</Component>;
};
