import { useState } from 'react';
import type { IPrimaryCodeFrontend } from '../../../../../types/entities/primaryCode';
import InfiniteList from '../../../../ui/infiniteList/InfiniteListV2';
import TotalLegend from '../../../../ui/totalLegend/TotalLegend';
import { ListHeader } from '../ListHeader';
import { Modal } from './components/Modal';
import { usePrimaryCodes } from './hooks';
import { useColumns } from './hooks/useColumns';
import { Filters } from '../../../../layout/NewFilters/Filters';
import { FilterSection } from '../../../../layout/NewFilters/FilterSection';
import { useTranslation } from 'react-i18next';
import { IFilter } from '../../../../../utils/url';
import { useCodeManagementFilterOptions } from '../useCodeManagementFilters';

export const PrimaryCodeManagement = () => {
  const [primaryCodeToEdit, setPrimaryCodeToEdit] = useState<IPrimaryCodeFrontend | null>(null);

  const [filters, setFilters] = useState<IFilter[]>([]);
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const { data, loading, total, fetchData, firstLoading, editElement } = usePrimaryCodes(filters);

  const { columns } = useColumns(setPrimaryCodeToEdit, data);

  const options = useCodeManagementFilterOptions();

  return (
    <Filters.Root open={open} setOpen={setOpen} filters={filters} setFilters={setFilters}>
      <Filters.Menu>
        <FilterSection.Multiple
          title={t('general.status')}
          field='status'
          type='in'
          options={options}
        />
      </Filters.Menu>
      {primaryCodeToEdit && (
        <Modal
          editElement={editElement}
          primaryCodeToEdit={primaryCodeToEdit}
          setPrimaryCodeToEdit={setPrimaryCodeToEdit}
        />
      )}
      <InfiniteList
        data={data}
        loading={loading}
        fetchData={fetchData}
        firstLoading={firstLoading}
        total={total}
        header={
          <ListHeader>
            <TotalLegend total={total} loading={loading} i18key='codeManagement.primaryCode' />
          </ListHeader>
        }
        columns={columns}
      />
    </Filters.Root>
  );
};
