import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PURCHASES_HELP, VIDEOS } from '../../../../constants';
import Breadcrumb from '../../../layout/breadcrumb/Breadcrumb';
import MainPageLayout from '../../../layout/mainPageLayout/MainPageLayout';
import Button from '../../../ui/button/Button';
import HelpCTA from '../../../ui/helpCTA/HelpCTA';
import './styles.scss';
import StepCarouselList from '../../../ui/stepCarouselList/StepCarouselList';
import { useSearchParams } from 'react-router-dom';
import { PurchasesManagement } from './purchasesManagement/PurchasesManagement';
import { SuppliersManagement } from './suppliersManagement/SuppliersManagement';
import { useFeatureFlags } from '../../../../customHooks/useFeatureFlags';
import { useGetSuppliers } from './suppliersManagement/hooks/useGetSuppliers';
import { IFilter } from '../../../../utils/url';
import { SuppliersNotificationBadge } from './components/SuppliersNotificationBadge';
import VideoTutorialPopup from '../../../ui/videoTutorialPopup/VideoTutorialPopup';
import Modal from '../../../ui/modal/Modal';
import LoomVideo from '../../../ui/videoPlayer/LoomVideo';
import { Status } from '../../../../types/utilsEnums/status';

const COMPONENT_SELECTOR = {
  purchases: PurchasesManagement,
  suppliers: SuppliersManagement
} as const;

const DEFAULT_FILTER_SUPPLIERS: IFilter[] = [
  {
    field: 'status',
    type: 'in',
    value: [Status.IN_PROGRESS, Status.ERROR]
  }
];

const ARTICLE_URL = {
  en: 'https://scribehow.com/shared/Upload_your_purchases_automatically__uTOsQ-CJTUO7kTf6i4Wzcw',
  es: 'https://scribehow.com/shared/Sube_tus_compras_automaticamente__4fz0eldlQSK3JRs_2VK_pw'
} as const;

function Purchases() {
  const { t, i18n } = useTranslation();

  const videoLink = VIDEOS.purchases[i18n.resolvedLanguage as keyof (typeof VIDEOS)['purchases']];

  const [query, setQuery] = useSearchParams({ manage: 'purchases' });

  const [showVideo, setShowVideo] = useState(false);

  const flags = useFeatureFlags();

  const [showUploadPurchasesFile, setShowUploadPurchasesFile] = useState(false);

  const { total, refreshData } = useGetSuppliers({ filters: DEFAULT_FILTER_SUPPLIERS });

  const handleOnCLickHelp = () => {
    const url = i18n.resolvedLanguage === 'es' ? PURCHASES_HELP.es : PURCHASES_HELP.en;
    window.open(url);
  };

  const Management = flags?.purchaseSuppliers
    ? COMPONENT_SELECTOR[query.get('manage') as keyof typeof COMPONENT_SELECTOR]
    : COMPONENT_SELECTOR.purchases;

  return (
    <Management.Context
      setShowUploadPurchasesFile={setShowUploadPurchasesFile}
      showUploadPurchasesFile={showUploadPurchasesFile}
      refreshSupplierBadgeNumber={refreshData}>
      <MainPageLayout
        sectionTitle={t('purchases.title')}
        title={t('purchases.start')}
        gridTemplateRows='4.5rem 0.05fr 10rem 4rem 1fr'
        description={
          <>
            <p className='m-0'>{t('purchases.startDescription1')}</p>
            <p className='m-0'>{t('purchases.startDescription2')}</p>
          </>
        }
        breadcrumb={<Breadcrumb />}
        helpCTA={
          <HelpCTA description={t('purchases.help')}>
            <div className='flex'>
              <Button
                lookAndFeel='primary'
                text={t('purchases.helpButton')}
                onClick={handleOnCLickHelp}
                size='small'
              />
            </div>
          </HelpCTA>
        }
        buttons={<Management.Buttons />}>
        <>
          <Management.Main>
            {flags?.purchaseSuppliers && (
              <StepCarouselList
                steps={[
                  {
                    id: 'purchases',
                    text: 'purchases.purchasesManagement',
                    isSelected: query.get('manage') === 'purchases'
                  },
                  {
                    id: 'suppliers',
                    text: <SuppliersNotificationBadge total={total} />,
                    isSelected: query.get('manage') === 'suppliers'
                  }
                ]}
                handleSelect={(id) => {
                  setQuery({ manage: id });
                }}
                lookAndFeel='big'
              />
            )}
          </Management.Main>

          {
            <>
              <VideoTutorialPopup
                title={t('purchases.video.title')}
                description={t('purchases.video.description')}
                image='/images/luis.png'
                onClick={() => setShowVideo(true)}
              />
              <Modal show={showVideo} onClose={() => setShowVideo(false)} width='700px'>
                <h3 className='font-headings-h3-sb mb-8'>{t('purchases.video.title')}</h3>
                <div className='flex-col gap-y-4 font-body-b2-sb mb-8'>
                  <p className='m-0'>{t('purchases.video.subTitle')}</p>
                  <a
                    href={
                      i18n.resolvedLanguage in ARTICLE_URL
                        ? ARTICLE_URL[i18n.resolvedLanguage as 'en' | 'es']
                        : ARTICLE_URL.es
                    }
                    target='_blank'
                    rel='noreferrer noopener'
                    className='link'>
                    {t('purchases.video.articleLink')}
                  </a>
                </div>
                <div className='flex-col gap-y-2 font-body-b2-sb'>
                  <p className='m-0'>{t('purchases.video.videoTitle')}</p>
                  <LoomVideo url={videoLink} />
                </div>
              </Modal>
            </>
          }
        </>
      </MainPageLayout>
    </Management.Context>
  );
}

export default Purchases;
