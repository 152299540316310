import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setNotification } from '../../../actions/notification';
import { GLEC_ARTICLE, TOCS_LIST, logisticsBlackList } from '../../../constants';
import { ROUTES } from '../../../constants/routes';
import { useFeatureFlags } from '../../../customHooks/useFeatureFlags';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import useTocs from '../../../customHooks/useTocs';
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb';
import MainPageLayout from '../../layout/mainPageLayout/MainPageLayout';
import Button from '../../ui/button/Button';
import SlideToggleFacade from '../../ui/formComponents/slideToggle3/SlideToggleFacade';
import HelpCTA from '../../ui/helpCTA/HelpCTA';
import Modal from '../../ui/modal/Modal';
import { SfcLogo } from '../hubs/components/sfcLogo/SfcLogo';
import Client from './components/client/Client';
import InputNuvoRecharges from './components/common/components/nuvo/inputNuvoRecharges';
import InputNuvoRequests from './components/common/components/nuvo/inputNuvoRequests';
import UploadFiles from './components/common/components/uploadFiles/UploadFiles';
import Shipment from './components/shipment/Shipment';
import Video from './components/video/Video';
import useFetchClients from './hooks/useFetchClients';
import './styles.scss';
import useClients from './hooks/useClients';

enum LogisticMode {
  CLIENT = 'client',
  SHIPMENT = 'shipment'
}

const Logistics = () => {
  const navigate = useNavigate();
  const flags = useFeatureFlags();
  const { t, i18n } = useTranslation();
  const { tocs, loading } = useTocs();

  const selectedOrganization = useSelectedOrganization();
  const dispatch = useDispatch();

  const [search, setSearch] = useState<string>('');
  const [showTripFilesModal, setShowTripFilesModal] = useState(false);
  const [showConsumptionFilesModal, setShowConsumptionFilesModal] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState<File[]>([]);

  const {
    fetchNextPage,
    clients,
    error,
    loading: loadingClients,
    hasNextPage
  } = useClients({ search });

  const [logisticMode, setLogisticMode] = useState(LogisticMode.CLIENT);

  const toggleOptions = [
    {
      id: LogisticMode.CLIENT,
      name: t('logistics.client')
    },
    {
      id: LogisticMode.SHIPMENT,
      name: t('logistics.shipment')
    }
  ];

  const handleSwitchMode = (value: string) => {
    setLogisticMode(value as LogisticMode);
  };

  const navigateToGlecMethodology = () => {
    const language = i18n.resolvedLanguage === 'es' ? 'es' : 'en';
    window.open(GLEC_ARTICLE[language], '_blank');
  };

  const parseDescription = () => {
    return (
      <>
        <Trans i18nKey='logistics.startDescription'>
          <>
            <ol className='text-left' style={{ margin: 0 }}>
              <li>
                {
                  'Si tienes centros de conexión logística y de almacenamiento (Hubs) en tus viajes, primero'
                }
                <span
                  className='highlight-text-color pointer link-style'
                  onClick={() => navigate(ROUTES.MEASURE_HUBS)}
                  style={{ textDecoration: 'underline' }}>
                  {t('logistics.addHubs')}
                </span>
              </li>
              <li>
                {'Descarga las plantillas excel desde "Sube tus viajes" y "Sube tus consumos"'}
              </li>
              <li className='add-1'>
                {
                  'Rellena los datos de la plantilla excel. Recuerda que el Hub ID debería ser el mismo que has creado en el paso '
                }
              </li>
              <li>{'Sube todos tus viajes y consumos en formato excel o csv'}</li>
              <li>
                {
                  'Después podrás visualizar y descargar la huella de carbono en formato pdf seleccionando el cliente y la fecha'
                }
              </li>
            </ol>
          </>
        </Trans>
      </>
    );
  };

  const handleGetDown = async () => {
    if (!hasNextPage || loadingClients) return;
    await fetchNextPage();
  };

  const handleSearchClient = async (searchValue: string) => {
    setSearch(searchValue);
  };

  const openTripFilesModal = () => {
    setShowTripFilesModal(true);
  };

  const closeTripFilesModal = () => {
    setShowTripFilesModal(false);
    setFilesUploaded([]);
    // fetchClients();
  };

  const openConsumptionFilesModal = () => {
    setShowConsumptionFilesModal(true);
  };

  const closeConsumptionFilesModal = () => {
    setShowConsumptionFilesModal(false);
    setFilesUploaded([]);
    // fetchClients();
  };

  const removeFile = (index: number) => {
    const newFilesUploaded = filesUploaded.filter((file, i) => i !== index);
    setFilesUploaded(newFilesUploaded);
  };

  const handleSaveFiles = (type: string) => {
    if (type === 'requests') {
      closeTripFilesModal();
      dispatch(setNotification(t('notification.tripFilesUploaded')));
    } else {
      closeConsumptionFilesModal();
      dispatch(setNotification(t('notification.consumptionFilesUploaded')));
    }
  };

  if (flags && !flags.logistics && selectedOrganization?.sector !== 'transport') {
    navigate(ROUTES.NOT_FOUND);
    return null;
  }

  const helptCta = (
    <HelpCTA description={t('logistics.doWeHelpYou')} style={{ marginBottom: 0 }}>
      <div className='flex logistics-help-buttons font-10'>
        <span className='link pointer font-10' onClick={navigateToGlecMethodology}>
          {t('logistics.glecMethodology')}
        </span>
        {t('general.and')}
        <span className='link pointer font-10' onClick={() => window.open(TOCS_LIST, '_blank')}>
          {t('logistics.newTocs')}
        </span>
      </div>
    </HelpCTA>
  );

  const buttons = (
    <>
      {flags?.nuvoLogistics && !logisticsBlackList.includes(selectedOrganization?.id || '') ? (
        <>
          <InputNuvoRequests
            lookAndFeel={error?.error === 'noDataRecharges' ? 'secondary' : 'primary'}
            tocs={tocs}
          />
          <InputNuvoRecharges
            lookAndFeel={error?.error === 'noDataRecharges' ? 'primary' : 'secondary'}
            tocs={tocs}
          />
        </>
      ) : (
        <>
          <Button
            lookAndFeel={error?.error === 'noDataRecharges' ? 'secondary' : 'primary'}
            onClick={openTripFilesModal}
            text={t('logistics.trips')}
            size='small'
            icon={
              error?.error === 'noDataRecharges'
                ? '/images/icons/uploadGradient.svg'
                : '/images/icons/uploadWhite.svg'
            }
          />
          <Button
            lookAndFeel={error?.error === 'noDataRecharges' ? 'primary' : 'secondary'}
            onClick={openConsumptionFilesModal}
            text={t('logistics.consumptions')}
            size='small'
            icon={
              error?.error === 'noDataRecharges'
                ? '/images/icons/uploadWhite.svg'
                : '/images/icons/uploadGradient.svg'
            }
          />
        </>
      )}
    </>
  );

  return (
    <>
      <MainPageLayout
        sectionTitle={t('logistics.title')}
        title={t('logistics.start')}
        description={parseDescription()}
        breadcrumb={<Breadcrumb />}
        buttons={!loading ? buttons : null}>
        <div className='logo-and-data-visualization'>
          <div className='data-visualization'>
            <span className='headline4-font'>{t('logistics.dataVisualization')}</span>
            <SlideToggleFacade
              handleSwitchMode={handleSwitchMode}
              idSelected={logisticMode}
              options={toggleOptions}
            />
          </div>
          {flags?.sfcLogo && <SfcLogo width='10%' />}
          {helptCta}
        </div>
        {logisticMode === LogisticMode.CLIENT && (
          <Client
            clients={clients}
            errorClient={error}
            handleGetDown={handleGetDown}
            loadingClients={loadingClients}
            handleSearchClient={handleSearchClient}
          />
        )}
        {logisticMode === LogisticMode.SHIPMENT && (
          <Shipment
            clients={clients}
            handleGetDown={handleGetDown}
            loadingClients={loadingClients}
            handleSearchClient={handleSearchClient}
          />
        )}
      </MainPageLayout>
      <Modal show={showTripFilesModal} onClose={closeTripFilesModal} width={'500px'}>
        <UploadFiles
          title={t('logistics.uploadYourTrips')}
          description={t('logistics.completeDataAndUpload')}
          // uploadFile={uploadFile}
          removeFile={removeFile}
          files={filesUploaded}
          type='requests'
          handleSaveFiles={handleSaveFiles}
          // fetchClients={fetchClients}
          maxSize={{
            value: 5e9,
            text: '500 MB'
          }}
        />
      </Modal>
      <Modal show={showConsumptionFilesModal} onClose={closeConsumptionFilesModal}>
        <UploadFiles
          title={t('logistics.uploadYourConsumptions')}
          description={t('logistics.completeDataAndUpload')}
          // uploadFile={uploadFile}
          removeFile={removeFile}
          files={filesUploaded}
          type='consumptions'
          handleSaveFiles={handleSaveFiles}
          // fetchClients={fetchClients}
          maxSize={{
            value: 5e9,
            text: '500 MB'
          }}
        />
      </Modal>
      <Video />
    </>
  );
};

export default Logistics;
