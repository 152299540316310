import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../../../types/entities/user';
import apiFetch from '../../../../utils/apiFetch';
import Button from '../../../ui/button/Button';
import './styles.scss';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';

type Props = {
  deletePurchase: (id: string) => void;
  purchaseToDelete: string;
  user: User | null;
};
function DeletePurchase({ user, purchaseToDelete, deletePurchase }: Props) {
  const { t } = useTranslation();

  function submitForm(e: { key: string; target: any }) {
    if (
      e.key === 'Enter' &&
      e.target?.id !== 'select-hidden' &&
      e.target?.id !== 'select-search' &&
      !loadingButton
    ) {
      handleDeletePurchase();
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', submitForm);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', submitForm);
    };
  });

  const [loadingButton, setLoadingButton] = useState(false);

  const handleDeletePurchase = async () => {
    if (user) {
      try {
        setLoadingButton(true);
        await apiFetch(
          'delete',
          `/purchases/${purchaseToDelete}`,
          {},
          { 'x-organization-id': user.selectedOrganization }
        );
        deletePurchase(purchaseToDelete);
        setLoadingButton(false);
      } catch (err) {
        console.error(err);
        setLoadingButton(false);
      }
    }
  };

  return (
    <div className='delete-modal'>
      <FormHeader
        title={t('purchases.deletePurchase')}
        description={t('purchases.areYouSureDelete')}
      />
      <div className='buttons'>
        <Button
          lookAndFeel='primary'
          text={t('purchases.delete')}
          onClick={handleDeletePurchase}
          loading={loadingButton}
        />
      </div>
    </div>
  );
}

export default DeletePurchase;
