import {
  HEAVY_FILE_STATUS,
  HeavyFilesStatus
} from '../../../../../types/entitiesEnums/heavyFilesStatus';
import { StatusTag } from '../../../../ui/statusTag';

type Props = {
  status: HeavyFilesStatus;
  text?: string;
};

export const UploadedFilesStatusTag: React.FC<Props> = ({ status, text }) => {
  if (status === HEAVY_FILE_STATUS.ERROR) {
    return <StatusTag status={HEAVY_FILE_STATUS.SUCCESS} text={text} />;
  }

  if (status === HEAVY_FILE_STATUS.IN_PROGRESS) {
    return <StatusTag status={HEAVY_FILE_STATUS.IN_QUEUE} text={text} />;
  }

  if (status === HEAVY_FILE_STATUS.INTERNAL_ERROR) {
    return <StatusTag status={HEAVY_FILE_STATUS.DELETED} text={text} />;
  }

  return <StatusTag status={status} text={text} />;
};
