import { Trans, useTranslation } from 'react-i18next';
import useSelectedOrganization from '../../../../../../../customHooks/useSelectedOrganization';
import { IFilter } from '../../../../../../../utils/url';
import Button from '../../../../../../ui/button/Button';
import InfiniteList from '../../../../../../ui/infiniteList/InfiniteListV2';
import TotalLegend from '../../../../../../ui/totalLegend/TotalLegend';
import { useGetSuppliers } from '../../hooks/useGetSuppliers';
import { useRequestMoreCredits } from '../../hooks/useRequestMoreCredits';
import styles from './styles.module.scss';
import { useColumns } from './useColumns';
import { useRedeemSupplierCredits } from './useRedeemCredits';
import { Status } from '../../../../../../../types/utilsEnums/status';

const I18N_KEY_PREFIX = 'purchases.suppliersTable.creditModal';

type Props = {
  handleClose: () => void;
};

const FILTERS: IFilter[] = [
  {
    field: 'status',
    type: 'in',
    value: [Status.IN_PROGRESS, Status.ERROR]
  }
];

const GET_SUPPLIERS_PARAMS = {
  filters: FILTERS,
  isInformaData: true
};

export const CreditsModalContent: React.FC<Props> = ({ handleClose }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: I18N_KEY_PREFIX
  });

  const org = useSelectedOrganization();

  const { handleRequestMoreCredits } = useRequestMoreCredits();

  const { columns } = useColumns();

  const { fetchData, total, data, loading, firstLoading } = useGetSuppliers(GET_SUPPLIERS_PARAMS);

  const redeemCredits = useRedeemSupplierCredits();

  const onSubmit = async () => {
    if (!org) return;

    await redeemCredits(org, data);

    handleClose();
  };

  const availableCredits = Number(org?.limit_official_suppliers_bonus) ?? 0;

  return (
    <div className={styles['main']}>
      <div className='flex-col gap-y-2'>
        <h3 className='headline3-font m-0'>{t('title')}</h3>
        <p className='subtitle2-font m-0'>
          {t('subTitle', { availableCredits: org?.limit_official_suppliers_bonus ?? 0 })}
        </p>
      </div>
      <Trans i18nKey={`${I18N_KEY_PREFIX}.description`} className='subtitle3-font m-0'>
        <p className='m-0 subtitle3-font'>
          Es importante que revises el listado de proveedores antes de enviar. Comprueba:
          <ul>
            <li>El nombre de cada proveedor es correcto.</li>, ,
            <li>
              No hay duplicidad de proveedores (evita mismos proveedores con diferente nombre).
            </li>
          </ul>
          Los créditos se contratan previamente. Se emplean para obtener información detallada de
          cada proveedor para calcular tu huella de carbono en compras. Si necesitas más créditos,
          <Button lookAndFeel='link' className='subtitle3-font' onClick={handleRequestMoreCredits}>
            solicita más creditos
          </Button>
          <br />
          <br />
          En el caso de que tengas menos créditos que números de proveedores sin código, se cogerán
          los proveedores que más compras asociadas tengan.
        </p>
      </Trans>
      <InfiniteList
        fetchData={fetchData}
        total={total}
        header={
          <div className='ml-auto'>
            <TotalLegend total={total} i18key={I18N_KEY_PREFIX} loading={loading} />
          </div>
        }
        firstLoading={firstLoading}
        loading={loading}
        data={data}
        columns={columns}
      />
      <div className='flex gap-x-4'>
        <Button lookAndFeel='secondary' text={t('close')} onClick={handleClose} />
        <Button
          lookAndFeel='primary'
          text={t('save', {
            creditsToRedeem: data.length > availableCredits ? availableCredits : data.length
          })}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};
