export enum Status {
  ACTIVE = 'active',
  PENDING = 'pending',
  ERROR = 'error',
  FILE_ERROR = 'file_error',
  IN_PROGRESS = 'in_progress',
  LOADING = 'loading',
  BLOCKED = 'blocked',
  IN_QUEUE = 'in_queue',
  SUCCESS = 'success',
  DELETING = 'deleting',
  DELETED = 'deleted',
  INTERNAL_ERROR = 'internal_error'
}
