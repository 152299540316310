import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../actions/notification';
import { ReactComponent as SVGMoreInfo } from '../../../../../assets/img/double_materiality_image.svg';
import { DOUBLE_MATERIALITY_ARTICLE } from '../../../../../constants';
import { UserContext } from '../../../../../context/userContext';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';
import { sendEmails } from '../../../../../services/api/emails';
import Breadcrumb from '../../../../layout/breadcrumb/Breadcrumb';
import SectionHeader from '../../../../layout/sectionHeader/SectionHeader';
import Button from '../../../../ui/button/Button';
import { CertificationPage } from '../../../../ui/certificationPage/CertificationPage';
import HelpCTA from '../../../../ui/helpCTA/HelpCTA';
import Modal from '../../../../ui/modal/Modal';
import StepCarouselList from '../../../../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../../../../ui/stepCarouselList/useStepCarousel';
import MoreInfoModal from './components/MoreInfoModal/MoreInfoModal';
import UploadModal from './components/UploadModal/UploadModal';
import { useSteps } from './hooks/useSteps';

const DoubleMateriality = () => {
  const { t } = useTranslation('', { keyPrefix: 'doubleMateriality' });
  const allSteps = useSteps();

  const dispatch = useDispatch();
  const user = useContext(UserContext);
  const selectedOrganization = useSelectedOrganization();

  const [loading, setLoading] = useState(false);

  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);

  const handleSubmit = async () => {
    setLoading(true);

    const subject = `El usuario ${user?.email} de la organización ${selectedOrganization?.company_name} ha clicado en más información de Doble Materialidad`;

    const response = await sendEmails(subject, undefined, undefined, [
      'cecilia@dcycle.io',
      'vanesa@dcycle.io',
      'ricardo.mendez@dcycle.io',
      'clients@dcycle.io'
    ]);

    if (response?.response?.status >= 400) {
      setLoading(false);
      return;
    }

    setLoading(false);
    dispatch(setNotification(t('notification.success')));
  };

  const { stepSelected, handleSelect, steps } = useStepCarousel({
    stepsText: allSteps
  });

  const handleGoToArticle = () => {
    window.open(DOUBLE_MATERIALITY_ARTICLE['es'], '_blank');
  };

  return (
    <section className='main-page-layout flex-col gap-y-8'>
      <SectionHeader title={t('title')} breadcrumb={<Breadcrumb />} />
      <div className='flex justify-between'>
        <StepCarouselList steps={steps} handleSelect={handleSelect} />
        <HelpCTA description={t('helpDM')}>
          <div className='flex justify-between'>
            <Button
              lookAndFeel='primary'
              text={t('helpDMButton')}
              size='tiny'
              onClick={handleGoToArticle}
              style={{ marginLeft: '0.3rem' }}
            />
          </div>
        </HelpCTA>
      </div>

      {stepSelected?.id !== 'irosGapAnalysis' ? (
        <>
          <CertificationPage.Layout>
            <div className='flex' style={{ height: '43vh' }}>
              <div className='flex-col'>
                <div className='flex-col justify-between' style={{ height: '34vh' }}>
                  <CertificationPage.Title>{t('title')}</CertificationPage.Title>
                  <CertificationPage.Description>
                    <Trans i18nKey='doubleMateriality.startDescription' />
                  </CertificationPage.Description>
                  <CertificationPage.SectionLayout>
                    <CertificationPage.SectionTitle>{t('benefits')}</CertificationPage.SectionTitle>
                    <CertificationPage.Checks
                      checks={[t('check1'), t('check2'), t('check3'), t('check4')]}
                    />
                  </CertificationPage.SectionLayout>
                </div>

                <CertificationPage.ButtonSection>
                  <>
                    <Button
                      lookAndFeel='primary'
                      text={t('upload')}
                      size='small'
                      onClick={() => setShowUpload(true)}
                      className='mt-1'
                    />
                    <Button
                      lookAndFeel='secondary'
                      text={t('moreInfoModal.send')}
                      size='medium'
                      onClick={handleSubmit}
                      loading={loading}
                      className='mt-1'
                    />
                  </>
                </CertificationPage.ButtonSection>
              </div>
            </div>
            <div>
              <SVGMoreInfo style={{ width: '98%' }} />
            </div>
          </CertificationPage.Layout>
          <Modal
            show={showUpload}
            onClose={() => setShowUpload(false)}
            maxWidth='650px'
            width='650px'>
            <UploadModal onClose={() => setShowUpload(false)} />
          </Modal>

          <Modal
            show={showMoreInfo}
            onClose={() => setShowMoreInfo(false)}
            maxWidth='613px'
            width='613px'>
            <MoreInfoModal onClose={() => setShowMoreInfo(false)} />
          </Modal>
        </>
      ) : (
        <div>
          <img src='/images/DM.svg' className='infography-esg-metrics' />
        </div>
      )}
    </section>
  );
};

export default DoubleMateriality;
