import { useState } from 'react';

import InputText from '../formComponents/inputText/InputText';
import SelectWrapper from './SelectWrapper';
import './styles.scss';
type Props = {
  columns: string[];
  valuesToMap: string[];
  onChange: (valuesSelected: SelectOptionFormat[]) => void;
};
const ColumnMapper = ({ columns, valuesToMap, onChange }: Props) => {
  const [valuesSelected, setValuesSelected] = useState<SelectOptionFormat[]>(
    columns.map((elem) => ({
      id: elem,
      name: ''
    }))
  );

  const onChangeValue = (value: SelectOptionFormat, column: string) => {
    // find if there is an element in valuesSelected with name equal to column
    const index = valuesSelected.findIndex((elem) => elem.id === column);
    if (index !== -1) {
      const newValuesSelected = [...valuesSelected];
      // // find prev value and set it to empty
      // const prevIndex = newValuesSelected.findIndex((elem) => elem.name === value.name);
      // if (prevIndex !== -1) {
      //   newValuesSelected[prevIndex] = {
      //     id: newValuesSelected[prevIndex].id,
      //     name: ''
      //   };
      // }
      newValuesSelected[index] = {
        id: column,
        name: value.name
      };

      setValuesSelected(newValuesSelected);
      onChange(newValuesSelected);
      return;
    }
    setValuesSelected([
      ...valuesSelected,
      {
        id: column,
        name: value.name
      }
    ]);
    onChange([
      ...valuesSelected,
      {
        id: column,
        name: value.name
      }
    ]);
  };

  return (
    <div className='column-mapper'>
      <div className='column-mapper__list'>
        {columns.map((column, index) => (
          <div className='row' key={`row-${index}`}>
            <InputText disabled icon={''} placeholder={''} value={column} />
            <SelectWrapper
              column={column}
              options={valuesToMap.map((value) => ({
                id: value,
                name: value
              }))}
              onChangeValue={onChangeValue}
              valuesSelected={valuesSelected}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColumnMapper;
