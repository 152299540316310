import Papa from 'papaparse';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGetBase64 from '../../../../../customHooks/useGetBase64';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';

import apiFetch from '../../../../../utils/apiFetch';
import Button from '../../../../ui/button/Button';
import ColumnMapper from '../../../../ui/columnMapper/ColumnMapper';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementFull from '../../../../ui/formComponents/formElementFull/FormElementFull';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import InputFileSingle from '../../../../ui/formComponents/inputFile/InputFileSingle';
import Select from '../../../../ui/formComponents/select/Select';

type FormData = {
  file: File | null;
  supplier: SelectOptionFormat;
  columnNames: string[];
  columnMappings: SelectOptionFormat[];
  errors: ErrorType[];
};
const CreateTemplate = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormData>({
    file: null,
    supplier: {
      id: '',
      name: ''
    },
    columnNames: [],
    columnMappings: [],
    errors: []
  });

  const [loadingButton, setLoadingButton] = useState(false);
  const { onChangeValue } = useOnChangeValue({ setFormData });

  const { fileBase64 } = useGetBase64(formData.file as File);

  const handleOnChangeFile = (file: File) => {
    setFormData((prev) => ({
      ...prev,
      file
    }));

    // The file is a CSV, get the columns of the CSV as an array
    // and set them as the values to map
    Papa.parse(file, {
      header: true,
      complete: (results: any) => {
        setFormData((prev) => ({ ...prev, columnNames: results.meta.fields })); // array of column names
      }
    });
  };

  const handleOnChangeColumnMappings = (columnMappings: SelectOptionFormat[]) => {
    setFormData((prev) => ({ ...prev, columnMappings }));
  };

  const handleCreateTemplate = async () => {
    // Convert to object
    const columnMappingsObject = formData.columnMappings.reduce((acc, curr) => {
      return curr.name ? { ...acc, [curr.id]: curr.name } : acc;
    }, {});

    // Call api endpoint to create template
    try {
      setLoadingButton(true);
      const response = await apiFetch('POST', '/purchases/template', {
        supplier: formData.supplier.id,
        column_mappings: columnMappingsObject,
        file: fileBase64
      });

      if (response.data.file_url) {
        window.open(response.data.file_url);
      }
      setLoadingButton(false);
    } catch (error) {
      setLoadingButton(false);
    }
  };

  return (
    <div className='create-template'>
      <FormHeader title={t('purchasesUpload.createTemplateTitle')} />
      <FormWrapper>
        <FormElementFull>
          <InputFileSingle
            handleFile={handleOnChangeFile}
            buttonText={t('purchasesUpload.upload')}
            labelText={t('purchasesUpload.uploadLabel')}
            fileName={formData.file?.name || ''}
            allowedTypes={['text/csv']}
            error={formData.errors.find((error) => error.error === 'file')}
          />
        </FormElementFull>
        <FormElementFull>
          <Select
            placeholder={''}
            label={t('purchasesUpload.supplier')}
            options={[
              {
                id: 'odoo',
                name: 'Odoo'
              }
            ]}
            value={formData.supplier}
            onChangeValue={onChangeValue('supplier')}
          />
        </FormElementFull>
        <ColumnMapper
          columns={['date', 'quantity', 'original_product', 'description', 'country', 'recycled']}
          valuesToMap={formData.columnNames}
          onChange={handleOnChangeColumnMappings}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          onClick={handleCreateTemplate}
          text={t('purchasesUpload.create')}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default CreateTemplate;
