import { useTranslation } from 'react-i18next';

type Props = {
  total: number;
};
export const SuppliersNotificationBadge: React.FC<Props> = ({ total }) => {
  const { t } = useTranslation();

  const classNames = ['suppliers-notification-badge'];

  if (total !== 0) {
    classNames.push('suppliers-notification-badge__active');
  }

  return (
    <div className={classNames.join(' ')}>
      <span className='text'>{t('purchases.suppliersManagement')}</span>
      {total !== 0 && <span className='badge'>{total}</span>}
    </div>
  );
};
