import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { completeTaskCurrentUser } from '../../../../../actions/auth';
import { setNotification } from '../../../../../actions/notification';
import { adaptBackToFrontTablePanelControl } from '../../../../../adapters/esgCustomMetrics';
import { ROUTES } from '../../../../../constants/routes';
import { FilterDatesContext } from '../../../../../context/filterDatesContext';
import { UserContext } from '../../../../../context/userContext';
import { EsgMetricsEnhanced } from '../../../../../types/entities/esgMetrics';
import { IGetBackendTask } from '../../../../../types/entities/task';
import { InputSize } from '../../../../../types/utilsEnums/input';
import { IFilter, ISorter } from '../../../../../utils/url';
import { useGetCustomMetrics } from '../../../../customize/customMetrics/hooks/useGetCustomMetrics';
import { FilterText } from '../../../../layout/NewFilters/FilterText';
import { Filters } from '../../../../layout/NewFilters/Filters';
import CreateTask from '../../../../tasks/components/createTask/CreateTask';
import EditTask from '../../../../tasks/components/editTask/EditTask';
import Button from '../../../../ui/button/Button';
import ButtonDropdown from '../../../../ui/buttonDropdown/ButtonDropdown';
import SlideToggleFacade from '../../../../ui/formComponents/slideToggle3/SlideToggleFacade';
import Icon from '../../../../ui/icon/Icon';
import InfiniteList from '../../../../ui/infiniteList/InfiniteListV2';
import Modal from '../../../../ui/modal/Modal';
import TotalLegendV2 from '../../../../ui/totalLegend/TotalLegendV2';
import NarrativeModal from './components/narrativeModal/NarrativeModal';
import PersonalizedMetricsBookMeeting from './components/personalizedMetricsBookMeeting/PersonalizedMetricsBookMeeting';
import useColumns from './hooks/useColumns';
import { useGetMetrics } from './hooks/useGetMetrics';
import useSortingOptions from './hooks/useSortingOptions';
import useToggleOptions from './hooks/useToggleOptions';
import { renderFrameworks, renderStatus } from './utils/utils';

const Metrics = () => {
  const { t: trans } = useTranslation();
  const { t } = useTranslation('translation', {
    keyPrefix: 'controlPanel.metrics'
  });

  const { startDate, endDate } = useContext(FilterDatesContext);
  const { data: customMetricsData, isLoading: isLoadingCustomMetrics } = useGetCustomMetrics();

  const navigate = useNavigate();
  const toggleOptions = useToggleOptions();
  const user = useContext(UserContext);

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState<string>(toggleOptions[0].id);

  const [filters, setFilters] = useState<IFilter[]>([
    {
      field: 'data_source',
      type: 'eq',
      value: 'T'
    },
    {
      field: 'esg_category',
      type: 'eq',
      value: mode
    }
  ]);

  const [sorters, setSorters] = useState<ISorter[]>([
    {
      field: 'name',
      order: 'asc'
    }
  ]);

  const [showPersonalizedModal, setShowPersonalizedModal] = useState(false);
  const [narrativeToEdit, setNarrativeToEdit] = useState<EsgMetricsEnhanced>();

  const [metricToEdit, setMetricToEdit] = useState<EsgMetricsEnhanced>();

  useEffect(() => {
    setFilters([
      {
        field: 'data_source',
        type: 'eq',
        value: 'T'
      },
      {
        field: 'esg_category',
        type: 'eq',
        value: mode
      }
    ]);
  }, [mode]);

  const columns = useColumns();

  const sortingOptions = useSortingOptions();

  const { data, isLoading, isFetchingNextPage, fetchNextPage, editElement } = useGetMetrics({
    filters,
    sorters,
    mode,
    startDate,
    endDate
  });

  const handleEditMetric = (task: IGetBackendTask) => {
    if (!metricToEdit) return;
    dispatch(setNotification(t('notification.createTask')));

    const editedMetric: EsgMetricsEnhanced = {
      ...metricToEdit,
      id: metricToEdit.id ?? '',
      responsible_task_id: task.id,
      assigned_to: {
        id: task.assigned_to,
        first_name: task.assigned_user,
        last_name: ''
      }
    };
    editElement(editedMetric);
    setMetricToEdit(undefined);
  };

  const renderEditButton = (metric: EsgMetricsEnhanced) => {
    const routeMapper: {
      [key: string]: string;
    } = {
      vehicles: ROUTES.MEASURE_VEHICLES,
      facilities: ROUTES.MEASURE_FACILITIES,
      hubs: ROUTES.MEASURE_HUBS,
      purchases: ROUTES.MEASURE_ACTIVITY_PURCHASES,
      employees: ROUTES.MEASURE_ACTIVITY_EMPLOYEES,
      travels: ROUTES.MEASURE_ACTIVITY_BUSINESS_TRAVELS,
      electricity: ROUTES.MEASURE_FACILITIES,
      transport_distribution_upstream:
        ROUTES.MEASURE_ACTIVITY_SHIPMENTS + '?transport_direction=upstream',
      transport_distribution_downstream:
        ROUTES.MEASURE_ACTIVITY_SHIPMENTS + '?transport_direction=downstream'
    };

    const onClickEdit = () => {
      if (mode === 'social' || mode === 'governance') {
        setNarrativeToEdit(metric);
      } else if (metric.source_type in routeMapper) {
        navigate(routeMapper[metric.source_type]);
      }
    };

    const dropdownOptions = [
      {
        id: 'edit',
        name: metric.status === 'COMPLETE' ? t('editMetric') : t('addData'),
        onClick: onClickEdit
      }
    ];

    if (metric?.responsible_task_id) {
      dropdownOptions.push({
        id: 'editResponsible',
        name: t('editResponsible'),
        onClick: () => {
          console.log('not yet implemented');
        }
      });
    } else {
      dropdownOptions.push({
        id: 'assignResponsible',
        name: t('assignResponsible'),
        onClick: () => {
          // setMetricToEdit(metric);
          console.log('not yet implemented');
        }
      });
    }

    return (
      <div className='flex justify-end' style={{ width: '100%' }}>
        <ButtonDropdown
          button={<Icon icon={'elipsis_horizontal'} color='gray-dark' />}
          options={dropdownOptions}
        />
      </div>
    );
  };

  const renderCategory = (category: string) => {
    if (!category) return '-';
    if (category === 'social_governance') {
      return t(`categories.${mode}`);
    }
    return t(`categories.${category}`);
  };
  const parseData = () => {
    if (!data) return [];

    return data.items?.map((item) => {
      return {
        ...item,
        name: t(`list.${item.name}`),
        category: renderCategory(item.category),
        frameworks: renderFrameworks(item.frameworks, t),
        status: renderStatus(item.status, trans),
        edit: renderEditButton(item)
      };
    });
  };

  const renderLegend = () => {
    let legendData = [];

    mode === 'personalized'
      ? (legendData = [
          { value: 3, label: t('frameworks').toLowerCase() },
          { value: customMetricsData?.length, label: t('datapoints').toLowerCase() }
        ])
      : (legendData = [
          { value: data?.total2, label: t('frameworks').toLowerCase() },
          { value: data?.total, label: t('datapoints').toLowerCase() }
        ]);

    return legendData;
  };

  const onSort = (value: SelectOptionFormat, sortDirection?: 'asc' | 'desc') => {
    if (!value) return;
    const sorters = [
      {
        field: value.id,
        order: sortDirection || 'asc'
      }
    ];
    setSorters(sorters);
  };

  return (
    <>
      <div className='control-panel-metrics flex flex-col'>
        <div className='flex gap-2 items-center'>
          <span className='font-14 weight-600'>{t('title')}</span>
          <SlideToggleFacade
            handleSwitchMode={(mode) => {
              setMode(mode);
            }}
            idSelected={mode}
            options={toggleOptions}
          />
        </div>
        <Filters.Root setFilters={setFilters} filters={filters} setOpen={setOpen} open={open}>
          <Filters.Menu></Filters.Menu>

          <InfiniteList
            data={
              mode === 'personalized'
                ? adaptBackToFrontTablePanelControl(customMetricsData, trans)
                : parseData()
            }
            columns={columns}
            loading={customMetricsData ? isLoadingCustomMetrics : isFetchingNextPage}
            firstLoading={isLoading}
            fetchData={fetchNextPage}
            total={data?.total}
            header={
              <>
                <div className='flex gap-x-2 items-center'>
                  {/* <FilterText
                    field='name'
                    type='il'
                    placeholder={t('searchMetric')}
                    size={InputSize.SMALL}
                  /> */}
                  {/* <Sorting
                      options={sortingOptions}
                      label={t('input.sorting.orderBy')}
                      placeholder={t('input.placeholderSelect')}
                      onSort={onSort}
                      defaultSort={{
                        id: sorters[0].field,
                        name: t(`input.sorting.datapoints.${sorters[0].field}`),
                        direction: sorters[0].order
                      }}
                    /> */}
                </div>

                {mode === 'personalized' && (
                  <div className='flex ml-auto'>
                    <Button
                      style={{ width: '7vw', marginRight: '0.6rem' }}
                      lookAndFeel='primary'
                      size='small'
                      onClick={() => navigate(ROUTES.CUSTOM_METRICS)}>
                      {t('uploadMetrics')}
                    </Button>
                    <Button
                      style={{ width: '7vw' }}
                      lookAndFeel='secondary'
                      size='small'
                      onClick={() => setShowPersonalizedModal(true)}>
                      {t('moreInfo')}
                    </Button>
                  </div>
                )}
                <TotalLegendV2 totalLabel='Total' totalElements={renderLegend()} />
              </>
            }
          />
        </Filters.Root>
      </div>

      {/* <Modal
        show={!!(metricToEdit && !metricToEdit.assigned_to.id)}
        onClose={() => setMetricToEdit(undefined)}
        maxWidth='600px'
        width='600px'>
        <CreateTask
          handleAddTask={handleEditMetric}
          users={users}
          tags={tags}
          selectedMetric={
            metricToEdit
              ? {
                  ...metricToEdit,
                  category:
                    metricToEdit?.category === 'social_governance'
                      ? mode
                      : metricToEdit
                      ? metricToEdit?.category
                      : ''
                }
              : undefined
          }
          preSetOrganization={null}
        />
      </Modal>
      <Modal
        show={!!(metricToEdit && metricToEdit.assigned_to.id)}
        onClose={() => setMetricToEdit(undefined)}
        maxWidth='600px'
        width='600px'>
        <EditTask
          handleEditTask={handleEditMetric}
          users={users}
          tags={tags}
          idTaskControlPanel={metricToEdit?.responsible_task_id}
        />
      </Modal> */}
      <Modal.WithPortal
        show={showPersonalizedModal}
        onClose={() => setShowPersonalizedModal(false)}
        width='584px'
        maxWidth='584px'>
        <PersonalizedMetricsBookMeeting />
      </Modal.WithPortal>
      <Modal.WithPortal
        show={!!narrativeToEdit}
        onClose={() => setNarrativeToEdit(undefined)}
        width='756px'
        maxWidth='756px'>
        {narrativeToEdit && (
          <NarrativeModal
            metric={narrativeToEdit}
            closeModal={() => setNarrativeToEdit(undefined)}
            type={mode}
            editElement={editElement}
          />
        )}
      </Modal.WithPortal>
    </>
  );
};

export default Metrics;
