import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import reactStringReplace from 'react-string-replace';
import { MAX_PAYLOAD_SIZE } from '../../../../../constants';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import { uploadFilePresignedUrl } from '../../../../../services/api/aws';
import { getPresignedUrlPurchases } from '../../../../../services/api/purchases';
import Button from '../../../../ui/button/Button';
import ErrorList from '../../../../ui/errorList/ErrorList';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementFull from '../../../../ui/formComponents/formElementFull/FormElementFull';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import InputFileSingle from '../../../../ui/formComponents/inputFile/InputFileSingle';
import Modal from '../../../../ui/modal/Modal';
import ErrorLabel from '../../../../ui/statusLabels/errorLabel/ErrorLabel';
import './styles.scss';

type FormData = {
  file: File | null;
  errors: ErrorType[];
};

type Props = {
  uploadPurchases: () => void;
  organizationId: string; // take out when at the moment upload is open to the user
};

const UploadPurchases = ({ uploadPurchases, organizationId }: Props) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<FormData>({
    file: null,
    errors: []
  });

  const [requestErrors, setRequestErrors] = useState<UploadFileBulkError[]>([]);
  const [loadingButton, setLoadingButton] = useState(false);

  const [showErrors, setShowErrors] = useState(false);

  const handleShowErrors = () => setShowErrors(true);
  const handleHideErrors = () => setShowErrors(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleUploadFile = async () => {
    if (!formData.file) {
      setFormData((prev) => ({
        ...prev,
        errors: [
          {
            error: 'file',
            description: t('error.fileRequired')
          }
        ]
      }));
      return;
    }
    setRequestErrors([]);
    const fileSize = formData.file.size / (1024 * 1024);

    if (fileSize >= MAX_PAYLOAD_SIZE) {
      setFormData((prev) => ({
        ...prev,
        errors: [
          {
            error: 'file',
            description: t('error.maxPayloadSize')
          }
        ]
      }));
      return;
    }

    setLoadingButton(true);

    const data = await getPresignedUrlPurchases(
      formData.file?.name ?? 'purchases.csv',
      organizationId
      // user?.selectedOrganization ?? '' // uncomment when upload at the moment is open to the user
    );

    if (!data) {
      setLoadingButton(false);
      return;
    }
    if ('invalid_csv_file' in data) {
      setRequestErrors(data.invalid_csv_file);
      setLoadingButton(false);
      return;
    }

    // upload file using the presigned url the same way as logistic
    const dataUpload = await uploadFilePresignedUrl(formData.file, data.upload_url);

    setLoadingButton(false);
    uploadPurchases();
  };

  const downloadFile = () => {
    // Download the csv file in public/files/purchases/purchases_template_Dcycle(internal).csv
    window.open('/files/purchases/purchases_template_Dcycle(internal).csv', '_blank');
  };

  const renderFileLabelTop = () => {
    const text = t('purchases.fileLabelTop');
    const replacements = [
      {
        search: '{{templates}}',
        component: (
          <span className='highlight-text-color pointer' onClick={downloadFile}>
            {t('purchases.exampleTemplates')}
          </span>
        )
      }
    ];

    let replacedText: string | React.ReactNode = text;

    replacements.forEach(({ search, component }) => {
      replacedText = reactStringReplace(replacedText as string, search, () => component);
    });

    return replacedText;
  };
  return (
    <div className='upload-purchases'>
      <FormHeader
        title={t('purchases.uploadPurchases')}
        description={t('purchases.uploadPurchasesDescription')}
      />
      <FormWrapper>
        <FormElementFull>
          <p className='file-label body1-bold-font'>{renderFileLabelTop()}</p>
          <InputFileSingle
            handleFile={onChangeValue('file')}
            buttonText={t('purchases.uploadCsvFile')}
            labelText={t('purchases.uploadCsvFileLabel')}
            fileName={formData.file?.name ?? ''}
            allowedTypes={['text/csv']}
            error={formData.errors.find((error) => error.error === 'file')}
          />
        </FormElementFull>
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('purchases.save')}
          size='medium'
          onClick={handleUploadFile}
          loading={loadingButton}
        />
      </FormButtonSection>
      {requestErrors.length > 0 && (
        <div className='error-wrapper'>
          <ErrorLabel onClick={handleShowErrors}>
            {t('error.rowsWithErrors', { rowNumber: requestErrors.length })}
          </ErrorLabel>
        </div>
      )}

      <Modal show={showErrors} onClose={handleHideErrors} width='600px'>
        <ErrorList
          data={requestErrors}
          columns={[
            {
              title: 'description',
              dataIndex: 'description',
              key: 'description'
            },
            {
              title: 'date',
              dataIndex: 'date',
              key: 'date'
            },
            {
              title: t('error.rowNumber'),
              dataIndex: 'row_index',
              key: 'row_index'
            },
            {
              title: 'Error',
              dataIndex: 'error',
              key: 'error'
            }
          ]}
        />
      </Modal>
    </div>
  );
};

export default UploadPurchases;
