import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NotificationContext } from '../../../../../context/notificationContext';
import { useFeatureFlags } from '../../../../../customHooks/useFeatureFlags';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';
import {
  IPurchaseSupplierBackend,
  IPurchaseSupplierFrontend
} from '../../../../../types/purchaseSupplier';
import { IFilter } from '../../../../../utils/url';
import { FilterSection } from '../../../../layout/NewFilters/FilterSection';
import { FilterText } from '../../../../layout/NewFilters/FilterText';
import { Filters } from '../../../../layout/NewFilters/Filters';
import FilterHandlers from '../../../../layout/NewFilters/FiltersHandlers';
import Button from '../../../../ui/button/Button';
import Icon from '../../../../ui/icon/Icon';
import { ICON_SIZE_MAP } from '../../../../ui/icon/utils/constants';
import InfiniteList from '../../../../ui/infiniteList/InfiniteListV2';
import LoaderTables from '../../../../ui/loaders/loaderTables/LoaderTables';
import Modal from '../../../../ui/modal/Modal';
import { StatusTag } from '../../../../ui/statusTag';
import TooltipWrapper from '../../../../ui/tooltip/TooltipWrapper';
import TotalLegend from '../../../../ui/totalLegend/TotalLegend';
import AddPurchase from '../addPurchase/AddPurchase';
import { PURCHASE_STATUS } from '../constants';
import { Buttons } from './components/Buttons';
import { CreditsModalContent } from './components/CreditsModalContent/CreditsModalContent';
import InviteSuppliersModalContent from './components/InviteSuppliersModalContent/InviteSuppliersModalContent';
import { ModalSupplier } from './components/ModalSupplier/ModalSupplier';
import { SuppliersManagementEditForm } from './components/SuppliersManagementEditForm/SuppliersManagementEditForm';
import { useColumns } from './hooks/useColumns';
import { useGetSuppliers } from './hooks/useGetSuppliers';
import useMockedData from './hooks/useMockedData';
import { useRefreshLimit } from './hooks/useRefreshLimit';
import { useRequestMoreCredits } from './hooks/useRequestMoreCredits';
import { useTotal } from './hooks/useTotal';

type ContextProps = {
  setShowUploadPurchasesFile: (value: boolean) => void;
  showUploadPurchasesFile: boolean;
  refreshSupplierBadgeNumber: () => void;
  children: React.ReactNode;
};

type Context = {
  setShowUploadPurchasesFile: (value: boolean) => void;
  showUploadPurchasesFile: boolean;
  setShowAddPurchase: (value: boolean) => void;
  showAddPurchase: boolean;
  total?: number;
  setTotal: React.Dispatch<React.SetStateAction<number | undefined>>;
  refreshSupplierBadgeNumber: () => void;
  totalLoading: boolean;
};

type EmptyTextProps = {
  isFiltering: boolean;
};
const EmptyText: React.FC<EmptyTextProps> = ({ isFiltering }) => {
  const { t } = useTranslation();

  if (isFiltering) {
    return <span>{t('purchases.suppliersTable.emptyFiltered')}</span>;
  }

  return <span>{t('purchases.suppliersTable.empty')}</span>;
};

type InformaLoaderProps = {
  shouldAppear: boolean;
};

const InformaLoader: React.FC<InformaLoaderProps> = ({ shouldAppear }) => {
  const { t } = useTranslation();

  if (!shouldAppear) return null;

  return (
    <TooltipWrapper text={t('purchases.suppliersTable.tooltip.fetching')} position='top'>
      <span className='flex gap-x-2 items-center subtitle3-font'>
        <LoaderTables mode='fit' />
        {t('purchases.suppliersTable.fetchingInformaData')}
      </span>
    </TooltipWrapper>
  );
};

const SupplierContext = createContext<Context | null>(null);

export const useSupplierContext = () => {
  const context = useContext(SupplierContext);
  if (!context) {
    throw new Error('useSupplier must be used within a SupplierProvider');
  }
  return context;
};

const SupplierProvider = ({
  children,
  setShowUploadPurchasesFile,
  showUploadPurchasesFile,
  refreshSupplierBadgeNumber
}: ContextProps) => {
  const [showAddPurchase, setShowAddPurchase] = useState(false);

  const { total, setTotal, loading } = useTotal();

  return (
    <SupplierContext.Provider
      value={{
        setShowUploadPurchasesFile,
        showUploadPurchasesFile,
        setShowAddPurchase,
        showAddPurchase,
        total,
        totalLoading: loading,
        setTotal,
        refreshSupplierBadgeNumber
      }}>
      {children}
    </SupplierContext.Provider>
  );
};

type MainProps = {
  children?: React.ReactNode;
};

const SuppliersManagementMain = ({ children }: MainProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const flags = useFeatureFlags();
  const selectedOrganization = useSelectedOrganization();

  const setNotification = useContext(NotificationContext);

  // This will check if the user is fetching informa data every 10 seconds if the limit_official_suppliers_bonus is "api_handling"
  useRefreshLimit();

  const organization = useSelectedOrganization();

  const [supplierToEdit, setSupplierToEdit] = useState<IPurchaseSupplierFrontend | null>(null);
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [showUseCredits, setShowUseCredits] = useState(false);
  const [showInviteSuppliers, setShowInviteSuppliers] = useState(false);

  const {
    showAddPurchase,
    setShowAddPurchase,
    setShowUploadPurchasesFile,
    setTotal,
    refreshSupplierBadgeNumber
  } = useSupplierContext();

  const { data, loading, firstLoading, fetchData, editElement, total, total2, refreshData } =
    useGetSuppliers({
      filters,
      refreshSupplierBadge: refreshSupplierBadgeNumber
    });

  const onCloseModal = () => {
    setShowAddPurchase(false);
    setShowUploadPurchasesFile(false);
  };

  const addPurchase = () => {
    setShowAddPurchase(false);
    setShowUploadPurchasesFile(false);
    dispatch(setNotification(t('notification.createPurchase')));
    setTotal((prev) => (prev ? prev + 1 : 1));
    refreshData();
    refreshSupplierBadgeNumber();
  };

  const editSupplier = (data: IPurchaseSupplierBackend) => {
    editElement(data);
    refreshSupplierBadgeNumber();
  };

  const onClickRedeem = () => {
    setShowUseCredits(true);
  };

  const onClickInviteSuppliers = () => {
    setShowInviteSuppliers(true);
  };

  // Limit official suppliers bonus can be a number as string or "api_handling"
  const creditsAvailable = Number(organization?.limit_official_suppliers_bonus);

  const isFetchingInformaData = Number.isNaN(creditsAvailable);

  const isBlocked = isFetchingInformaData || !creditsAvailable;

  const onEdit = (id?: string) => () => {
    if (isFetchingInformaData) return;

    setSupplierToEdit(data?.find((elem) => elem.id === id) ?? null);
  };

  const { columns } = useColumns({ onEdit, isFetchingInforma: isFetchingInformaData });

  const { handleRequestMoreCredits } = useRequestMoreCredits();

  useEffect(() => {
    if (!isFetchingInformaData) {
      refreshData();
    }
  }, [isFetchingInformaData]);

  const {
    columns: mockedColumns,
    data: mockedData,
    modalData,
    handleCloseModalData
  } = useMockedData();

  // (flags as any).mockedSuppliers = true;

  const isMocked =
    selectedOrganization?.company_name === 'Dcycle Demos' ||
    selectedOrganization?.company_name === 'Logística Marta';

  const columnsToRender = isMocked ? mockedColumns : columns;
  const dataToRender = isMocked ? mockedData : data;
  const totalToRender = isMocked ? mockedData.length : total;
  const fetchDataToRender = isMocked ? () => new Promise<void>(() => null) : fetchData;

  return (
    <>
      <div className='flex justify-between' style={{ marginTop: '2rem' }}>
        {children}
        <div className='flex gap-x-2 items-center'>
          {!isFetchingInformaData && (
            <TooltipWrapper
              text={t('purchases.suppliersTable.tooltip.myCredits')}
              position='top'
              style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
              <span className='flex gap-x-2 font-body-b2-sb items-center whitespace-nowrap'>
                <Icon icon='cash' color='gradient' />
                {t('purchases.suppliersTable.myCredits', {
                  availableCredits: creditsAvailable
                })}
              </span>
            </TooltipWrapper>
          )}
          <TooltipWrapper
            text={t(
              `purchases.suppliersTable.tooltip.${
                isFetchingInformaData ? 'cannotRedeem' : 'redeem'
              }`
            )}
            position='top'
            shouldAppear={isBlocked}>
            <Button
              size='small'
              lookAndFeel='primary'
              text={t('purchases.suppliersTable.redeem')}
              onClick={onClickRedeem}
              iconNode={<Icon icon='cash' color='white' size={ICON_SIZE_MAP.SMALL} />}
              style={{ whiteSpace: 'nowrap' }}
              disabled={isBlocked}
            />
          </TooltipWrapper>
          <Button
            lookAndFeel='secondary'
            text={t('purchases.suppliersTable.requestMore')}
            size='small'
            onClick={handleRequestMoreCredits}
          />
        </div>
      </div>
      <Filters.Root setFilters={setFilters} setOpen={setOpen} open={open} filters={filters}>
        <Filters.Menu>
          <FilterSection.Multiple
            type='in'
            field='status'
            title={t('purchases.suppliersTable.filters.status')}
            options={Object.values(PURCHASE_STATUS).map((status) => ({
              label: <StatusTag status={status} text={`purchases.suppliersTable.tags.${status}`} />,
              value: status
            }))}
          />
        </Filters.Menu>
        <Modal show={showAddPurchase} onClose={onCloseModal} width='600px' maxWidth='600px'>
          <AddPurchase addPurchase={addPurchase} />
        </Modal>
        <Modal show={Boolean(supplierToEdit)} onClose={() => setSupplierToEdit(null)}>
          {supplierToEdit && (
            <SuppliersManagementEditForm
              setData={setSupplierToEdit}
              editElement={editSupplier}
              data={supplierToEdit}
            />
          )}
        </Modal>
        <Modal
          show={showUseCredits}
          onClose={() => setShowUseCredits(false)}
          width='720px'
          maxWidth='720px'>
          {showUseCredits && <CreditsModalContent handleClose={() => setShowUseCredits(false)} />}
        </Modal>
        <Modal
          show={showInviteSuppliers}
          onClose={() => setShowInviteSuppliers(false)}
          width='700px'
          maxWidth='700px'>
          {showInviteSuppliers && <InviteSuppliersModalContent />}
        </Modal>
        <InfiniteList
          fetchData={fetchDataToRender}
          header={
            <>
              <div className='flex gap-x-2'>
                <FilterText
                  field='business_name'
                  type='il'
                  placeholder={t('purchases.suppliersTable.filters.placeholders.businessName')}
                />

                <FilterHandlers blacklistedFilters={{ all: ['business_name'] }} />
              </div>

              <div className='flex gap-x-4 items-center'>
                <InformaLoader shouldAppear={isFetchingInformaData} />

                <Button
                  size='small'
                  lookAndFeel='primary'
                  text={t('purchases.suppliersTable.inviteSuppliers')}
                  onClick={onClickInviteSuppliers}
                  iconNode={<Icon icon='link' color='white' size={ICON_SIZE_MAP.SMALL} />}
                  style={{ whiteSpace: 'nowrap' }}
                />

                <TotalLegend
                  total={totalToRender}
                  loading={loading}
                  i18key='purchases.suppliersTable'
                  total2={total2}
                />
              </div>
            </>
          }
          total={totalToRender}
          firstLoading={firstLoading}
          columns={columnsToRender}
          data={dataToRender}
          loading={loading}
          emptyText={<EmptyText isFiltering={Boolean(filters.length)} />}
        />
      </Filters.Root>
      <Modal show={!!modalData} onClose={handleCloseModalData} maxWidth='650px' width='650px'>
        <ModalSupplier onClose={handleCloseModalData} modalData={modalData} />
      </Modal>
    </>
  );
};

export const SuppliersManagement = {
  Main: SuppliersManagementMain,
  Buttons,
  Context: SupplierProvider
};
