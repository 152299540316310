import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { UserContext } from '../../../../../../context/userContext';
import { sendEmails } from '../../../../../../services/api/emails';
import { setNotification } from '../../../../../../actions/notification';
import { useTranslation } from 'react-i18next';

export const useRequestMoreCredits = () => {
  const user = useContext(UserContext);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const org = user?.organizations?.find((org) => org.id === user?.selectedOrganization);

  const handleRequestMoreCredits = async () => {
    const subject = `El usuario ${user?.email} de la organización ${org?.company_name} solicita más créditos para proveedores`;
    const response = await sendEmails(subject, undefined, undefined, ['clients@dcycle.io']);

    if (response?.response?.status >= 400) {
      dispatch(setNotification(t('notification.requestMoreCreditsError')));
      return;
    }

    dispatch(setNotification(t('notification.requestMoreCredits')));
  };

  return { handleRequestMoreCredits };
};
