import { useState } from 'react';
import Select from '../formComponents/select/Select';

type Props = {
  column: string;
  options: SelectOptionFormat[];
  onChangeValue: (value: SelectOptionFormat, column: string) => void;
  valuesSelected: SelectOptionFormat[];
};
const SelectWrapper = ({ column, options, onChangeValue, valuesSelected }: Props) => {
  const [value, setValue] = useState<SelectOptionFormat>({
    id: '',
    name: ''
  });

  // useEffect(() => {
  //   console.log('column', column);
  //   console.log('valuesSelected', valuesSelected);
  //   const index = valuesSelected.findIndex((elem) => elem.id === column);
  //   if (index !== -1) {
  //     setValue(valuesSelected[index]);
  //   }
  // }, [valuesSelected]);

  const onChangeSelectValue = (selected: SelectOptionFormat) => {
    setValue(selected);
    onChangeValue(selected, column);
  };
  return (
    <Select options={options} placeholder={''} onChangeValue={onChangeSelectValue} value={value} />
  );
};

export default SelectWrapper;
