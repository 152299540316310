import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../actions/notification';
import useGetBase64 from '../../../../customHooks/useGetBase64';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';

import checkFormErrors from '../../../../utils/checkFormErrors';
import Breadcrumb from '../../../layout/breadcrumb/Breadcrumb';
import MainPageLayout from '../../../layout/mainPageLayout/MainPageLayout';
import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import InputText from '../../../ui/formComponents/inputText/InputText';
import Modal from '../../../ui/modal/Modal';
import CreateTemplate from './createTemplate/CreateTemplate';
import './styles.scss';

import UploadFiles from '../../../measure/activity/purchases/uploadFiles/UploadFiles';
import DeletePurchases from './deletePurchases/DeletePurchases';
import GetPurchasesError from './getPurchasesError/GetPurchasesError';
import RecalculatePurchases from './recalculatePurchases/RecalculatePurchases';

type FormData = {
  organizationId: string;
  file: File | null;
  errors: ErrorType[];
};

const commonInputProps = {
  height: '31px',
  size: 'small' as 'small' | 'big' | undefined
};
const PurchasesUpload = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormData>({
    organizationId: '',
    file: null,
    errors: []
  });
  const [loadingButton, setLoadingButton] = useState(false);
  const [showCreateTemplate, setShowCreateTemplate] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showGetPurchasesWithErrors, setShowGetPurchasesWithErrors] = useState(false);
  const [showRecalculateModal, setShowRecalculateModal] = useState(false);
  const [showUploadFile, setShowUploadFile] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const { fileBase64 } = useGetBase64(formData.file as File);

  const dispatch = useDispatch();

  const handleShowCreateTemplate = () => {
    setShowCreateTemplate(true);
  };

  const handleShowGetPurchasesWithErrors = () => {
    setShowGetPurchasesWithErrors(true);
  };

  const handleHideModal = () => {
    setShowCreateTemplate(false);
    setShowDeleteModal(false);
    setShowGetPurchasesWithErrors(false);
    setShowRecalculateModal(false);
  };

  const handleDelete = () => {
    const errors = checkFormErrors(formData, formData.errors, ['file']);
    if (errors.length > 0) {
      setFormData({
        ...formData,
        errors
      });
      return;
    }
    setShowDeleteModal(true);
  };

  const handleRecalculate = () => {
    const errors = checkFormErrors(formData, formData.errors, ['file']);
    if (errors.length > 0) {
      setFormData({
        ...formData,
        errors
      });
      return;
    }
    setShowRecalculateModal(true);
  };

  const handleShowUploadFile = () => {
    if (formData.organizationId === '') {
      dispatch(setNotification(t('notification.organizationIdRequired')));
      return;
    }
    setShowUploadFile(true);
  };

  const handleHideUploadFile = () => {
    setShowUploadFile(false);
  };

  const handleUploadFile = () => {
    handleHideUploadFile();
    dispatch(setNotification(t('notification.uploadPurchasesSuccess')));
  };

  return (
    <>
      <MainPageLayout
        sectionTitle={t('purchasesUpload.title')}
        title={t('purchasesUpload.createTemplateTitle')}
        description={t('purchasesUpload.createTemplateStartDescription')}
        breadcrumb={<Breadcrumb />}
        buttons={
          <>
            <Button
              lookAndFeel={'primary'}
              onClick={handleShowCreateTemplate}
              icon={'/images/icons/plusWhite.svg'}
              text={t('purchasesUpload.create')}
              size='small'
            />
            <Button
              lookAndFeel='secondary'
              onClick={handleShowGetPurchasesWithErrors}
              text={t('purchasesUpload.getPurchasesWithErrors')}
              size='small'
            />
            <Button
              lookAndFeel='secondary'
              text={t('purchasesUpload.upload')}
              size='small'
              onClick={handleShowUploadFile}
            />
          </>
        }>
        <div className='purchases-upload card'>
          <FormWrapper>
            <InputText
              {...commonInputProps}
              icon={'/images/icons/organization.svg'}
              placeholder={t('purchasesUpload.organizationId')}
              label={t('purchasesUpload.organizationId')}
              value={formData.organizationId}
              onChangeValue={onChangeValue('organizationId')}
              error={formData.errors.find((error) => error.error === 'organizationId')}
            />
            {/* <FormElementFull>
              <InputFileSingle
                handleFile={onChangeValue('file')}
                buttonText={t('purchasesUpload.upload')}
                labelText={t('purchasesUpload.uploadLabel')}
                fileName={formData.file?.name || ''}
                allowedTypes={['text/csv']}
                error={formData.errors.find((error) => error.error === 'file')}
              />
            </FormElementFull> */}
          </FormWrapper>
          <FormButtonSection>
            {/* <Button
              lookAndFeel='primary'
              text={t('purchasesUpload.save')}
              onClick={handleSubmit}
              loading={loadingButton}
            /> */}
            <Button
              lookAndFeel='secondary'
              text={t('purchasesUpload.delete')}
              onClick={handleDelete}
            />
            <Button
              lookAndFeel='secondary'
              text={t('purchasesUpload.recalculate')}
              onClick={handleRecalculate}
            />
          </FormButtonSection>
        </div>
      </MainPageLayout>
      <Modal show={showCreateTemplate} onClose={handleHideModal} width='600px'>
        <CreateTemplate />
      </Modal>
      <Modal show={showDeleteModal} onClose={handleHideModal}>
        <DeletePurchases
          organizationId={formData.organizationId}
          deleteCallback={handleHideModal}
        />
      </Modal>
      <Modal show={showRecalculateModal} onClose={handleHideModal}>
        <RecalculatePurchases
          organizationId={formData.organizationId}
          recalculateCallback={handleHideModal}
        />
      </Modal>
      <Modal show={showGetPurchasesWithErrors} onClose={handleHideModal}>
        <GetPurchasesError />
      </Modal>
      <Modal
        show={(formData.organizationId && showUploadFile) as boolean}
        onClose={handleHideUploadFile}
        width='700px'
        maxWidth='700px'>
        <UploadFiles uploadPurchases={handleUploadFile} organizationId={formData.organizationId} />
      </Modal>
    </>
  );
};

export default PurchasesUpload;
