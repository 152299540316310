import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { User } from '../../../types/entities/user';
import { ConsumptionType } from '../../../types/entities/vehicleConsumption';
import apiFetch from '../../../utils/apiFetch';
import Button from '../../ui/button/Button';
import './styles.scss';

type Props = {
  deleteConsumption: (id: string) => void;
  consumptionToDelete: ConsumptionType;
  user: User | null;
};
function DeleteConsumption({ user, consumptionToDelete, deleteConsumption }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loadingButton, setLoadingButton] = useState(false);

  function submitForm(e: { key: string; target: any }) {
    if (
      e.key === 'Enter' &&
      e.target?.id !== 'select-hidden' &&
      e.target?.id !== 'select-search' &&
      !loadingButton
    ) {
      handleDeleteConsumption();
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', submitForm);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', submitForm);
    };
  });

  const handleDeleteConsumption = () => {
    setLoadingButton(true);
    apiFetch('delete', `/vehicle_consumptions/${consumptionToDelete.id}`, null, {
      'x-organization-id': user?.selectedOrganization
    })
      .then((response) => {
        deleteConsumption(consumptionToDelete.id);

        setLoadingButton(false);
      })
      .catch((err) => {
        setLoadingButton(false);
      });
  };

  return (
    <div className='delete-modal'>
      <h1 className='headline4-font'>{t('vehicleDetail.deleteConsumption')}</h1>
      <h2 className='subtitle3-font '>{t('vehicleDetail.areYouSureDelete')}</h2>
      <div className='buttons'>
        <Button
          lookAndFeel='primary'
          text={t('vehicleDetail.delete')}
          onClick={handleDeleteConsumption}
          loading={loadingButton}
        />
      </div>
    </div>
  );
}

export default DeleteConsumption;
