import { getPurchases } from '../../../../../../services/api/purchases';
import { IPurchaseGeneralBackend, IPurchaseRest } from '../../../../../../types/purchases';
import { IFilter, ISorter } from '../../../../../../utils/url';
import useFetchInfiniteList from '../../../../../ui/infiniteList/hooks/useFetchInfiniteList';

type Params = {
  filters?: IFilter[];
  sorters?: ISorter[];
  refreshSupplierBadge?: () => void;
};
const makeFetch =
  ({ filters, sorters, refreshSupplierBadge }: Params) =>
  async (page: number) => {
    const queryParams = [
      {
        key: 'page',
        value: page
      }
    ];
    const response = await getPurchases({ queryParams, filters, sorters });

    refreshSupplierBadge?.();

    return response;
  };

export const useGetPurchases = ({ filters, sorters, refreshSupplierBadge }: Params) => {
  const { rest, ...response } = useFetchInfiniteList<IPurchaseGeneralBackend, IPurchaseRest>(
    makeFetch({ filters, sorters, refreshSupplierBadge }),
    [JSON.stringify(filters), JSON.stringify(sorters)]
  );

  return {
    ...response,
    total2: rest?.total2
  };
};
