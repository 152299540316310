import { patchPurchaseSupplierAdapter } from '../../components/measure/activity/purchases/suppliersManagement/adapters/patchPurchaseSupplierAdapter';
import { IPurchaseSupplierBackend, IPurchaseSupplierFrontend } from '../../types/purchaseSupplier';
import apiFetch from '../../utils/apiFetch';
import { IFilter, QueryParams, getUrl } from '../../utils/url';

const URL = '/purchase_suppliers';

type GetParams = {
  queryParams?: QueryParams[];
  filters?: IFilter[];
};

export const getPurchaseSuppliers = async ({ queryParams, filters }: GetParams) => {
  try {
    const { data } = await apiFetch('GET', getUrl(URL, { queryParams, filters }));

    return data as Promise<Pagination<IPurchaseSupplierBackend>>;
  } catch (error) {
    return undefined;
  }
};

export const patchSupplier = async (supplier: Partial<IPurchaseSupplierFrontend>) => {
  try {
    const { data } = await apiFetch(
      'PATCH',
      `${URL}/${supplier.id}`,
      patchPurchaseSupplierAdapter(supplier)
    );

    return data as Promise<IPurchaseSupplierBackend>;
  } catch (error) {
    return undefined;
  }
};

export const redeemSupplierCredits = async (
  orgId: string,
  suppliers: IPurchaseSupplierFrontend[]
) => {
  const supplier_ids = suppliers.map((supplier) => supplier.id);
  const response = await apiFetch('POST', `${URL}/bonus/${orgId}`, { supplier_ids });

  return response.data as Promise<void>;
};

export const getOrganizationSupplierCredits = async (organizationId: string) => {
  try {
    const response = await apiFetch('GET', `/purchase_suppliers/bonus/${organizationId}`);

    return response.data as string;
  } catch (error) {
    return null;
  }
};
