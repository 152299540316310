import { useTranslation } from 'react-i18next';
import { Column } from '../../../../../../types/table';
import Icon from '../../../../../ui/icon/Icon';
import { StatusTag } from '../../../../../ui/statusTag';
import TooltipWrapper from '../../../../../ui/tooltip/TooltipWrapper';
import { mapToPurchaseFramework } from '../../../../../../utils/mapToPurchaseFramework';
import { IPurchaseSupplierFrontend } from '../../../../../../types/purchaseSupplier';
import { Status } from '../../../../../../types/utilsEnums/status';

type Params = {
  onEdit: (id?: string) => () => void;
  isFetchingInforma?: boolean;
};

export const useColumns = ({ onEdit, isFetchingInforma }: Params) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'purchases.suppliersTable'
  });
  const columns: Column<IPurchaseSupplierFrontend>[] = [
    {
      title: t('columns.supplier'),
      key: 'name',
      dataIndex: 'name',
      width: 800
    },
    {
      title: t('columns.country'),
      key: 'country',
      dataIndex: 'country',
      width: 100
    },
    {
      title: t('columns.code'),
      key: 'code',
      dataIndex: ['purchaseCode', 'code'],
      width: 100
    },
    {
      title: t('columns.codeType'),
      key: 'codeType',
      dataIndex: ['purchaseCode', 'framework'],
      render: (framework) => (framework ? mapToPurchaseFramework(framework) : '-'),
      width: 200
    },
    {
      title: t('columns.purchasesCount'),
      key: 'purchasesCount',
      dataIndex: 'purchasesCount',
      width: 200
    },
    {
      title: t('columns.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => {
        if (status === 'active') {
          return <StatusTag status={Status.ACTIVE} text={t(`tags.${status}`)} />;
        }

        return (
          <>
            <TooltipWrapper text={t(`tooltip.${status}`)} position='top'>
              <StatusTag status={status as Status} text={t(`tags.${status}`)} />
            </TooltipWrapper>
          </>
        );
      },
      width: 150
    },
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      align: 'right',
      render: (id) => (
        <TooltipWrapper
          shouldAppear={isFetchingInforma}
          position='top'
          text={t('tooltip.cannotEdit')}>
          <Icon
            icon='edit'
            color={isFetchingInforma ? 'gray' : 'gradient'}
            className='pointer'
            onClick={onEdit(id)}
            style={{ cursor: isFetchingInforma ? 'not-allowed' : 'pointer' }}
          />
        </TooltipWrapper>
      )
    }
  ];

  return { columns };
};
