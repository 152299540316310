import { useContext, useState } from 'react';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import { useTranslation } from 'react-i18next';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import Button from '../../../../ui/button/Button';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../actions/notification';
import { recalculatePurchasesFromOrganization } from '../../../../../services/api/purchases';
import { UserContext } from '../../../../../context/userContext';

type Props = {
  organizationId: string;
  recalculateCallback: () => void;
};
const RecalculatePurchases = ({ organizationId, recalculateCallback }: Props) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const [loadingButton, setLoadingButton] = useState(false);

  const dispatch = useDispatch();

  const handleRecalculate = async () => {
    try {
      setLoadingButton(true);
      const data = await recalculatePurchasesFromOrganization(organizationId, user?.id ?? '');
      if (!data) {
        dispatch(setNotification(t('error.somethingWentWrong')));
      }
      setLoadingButton(false);
      recalculateCallback();
    } catch (error) {
      console.error(error);
      dispatch(setNotification(t('error.somethingWentWrong')));
      recalculateCallback();

      setLoadingButton(false);
    }
  };

  return (
    <div className='recalculate-purchases'>
      <FormHeader
        title={t('purchasesUpload.recalculateTitle')}
        description={t('purchasesUpload.recalculateDescription')}
      />
      <FormButtonSection>
        <Button
          text='Recalculate'
          lookAndFeel='primary'
          onClick={handleRecalculate}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default RecalculatePurchases;
