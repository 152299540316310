import React, { useState } from 'react';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import { useTranslation } from 'react-i18next';
import { deleteGrouping } from '../../../../services/api/grouping';
import Button from '../../../ui/button/Button';

type Props = {
  groupingToDelete: string;
  removeGrouping: (id: string) => void;
};
const DeleteGrouping = ({ groupingToDelete, removeGrouping }: Props) => {
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const onSubmit = async () => {
    setError(false);
    setLoadingButton(true);
    const data = await deleteGrouping(groupingToDelete);
    setLoadingButton(false);
    if (!data) {
      setError(true);
      return;
    }

    removeGrouping(groupingToDelete);
  };

  return (
    <div>
      <FormHeader title={t('grouping.deleteTitle')} description={t('grouping.deleteDescription')} />
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('grouping.delete')}
          onClick={onSubmit}
          loading={loadingButton}
        />
      </FormButtonSection>
      {error && (
        <span className='body1-font error-text-color'>{t('error.somethingWentWrong')}</span>
      )}
    </div>
  );
};

export default DeleteGrouping;
