import { useDispatch } from 'react-redux';
import { redeemSupplierCredits } from '../../../../../../../services/api/purchaseSuppliers';
import { Organization } from '../../../../../../../types/entities/organization';
import { IPurchaseSupplierFrontend } from '../../../../../../../types/purchaseSupplier';
import { setNotification } from '../../../../../../../actions/notification';
import { useTranslation } from 'react-i18next';
import { setOrganizationLimitOfficialSuppliersBonusAction } from '../../../../../../../actions/auth';

export const useRedeemSupplierCredits = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation('translation', { keyPrefix: 'notification' });

  return async (org: Organization, data: IPurchaseSupplierFrontend[]) => {
    try {
      await redeemSupplierCredits(org.id, data);

      dispatch(setNotification(t('supplierCreditsRedeem')));

      dispatch(setOrganizationLimitOfficialSuppliersBonusAction(org.id, 'api_handling'));
    } catch {
      dispatch(setNotification(t('supplierCreditsRedeemError')));
    }
  };
};
