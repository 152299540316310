import { useState } from 'react';
import TotalLegend from '../../../../ui/totalLegend/TotalLegend';
import { ListHeader } from '../ListHeader';
import { InfiniteList } from './components/InfiniteList';
import { Modal } from './components/Modal';
import { useSecondaryCodes } from './hooks';
import { Filters } from '../../../../layout/NewFilters/Filters';
import { FilterSection } from '../../../../layout/NewFilters/FilterSection';
import { useTranslation } from 'react-i18next';
import { IFilter } from '../../../../../utils/url';
import { useCodeManagementFilterOptions } from '../useCodeManagementFilters';

export const SecondaryCodeManagement = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState<IFilter[]>([]);

  const { data, loading, total, fetchData, firstLoading, editElement } = useSecondaryCodes(filters);

  const options = useCodeManagementFilterOptions();

  return (
    <Filters.Root open={open} setOpen={setOpen} filters={filters} setFilters={setFilters}>
      <Filters.Menu>
        <FilterSection.Multiple
          title={t('general.status')}
          field='status'
          type='in'
          options={options}
        />
      </Filters.Menu>
      <Modal.Provider>
        <Modal editElement={editElement} />
        <InfiniteList
          data={data}
          loading={loading}
          fetchData={fetchData}
          firstLoading={firstLoading}
          total={total}
          header={
            <ListHeader>
              <TotalLegend total={total} loading={loading} i18key='codeManagement.secondaryCode' />
            </ListHeader>
          }
        />
      </Modal.Provider>
    </Filters.Root>
  );
};
