import { useState } from 'react';
import apiFetch from '../utils/apiFetch';

type InputText = {
  name: string;
  value: string;
};

type Filters = {
  inputTextList?: InputText[];
  startDate?: number;
  endDate?: number;
};

type UsePaginatedListParameters = {
  url: string;
  listSize: number;
  setLoading: (loading: boolean) => void;
  setStartDate?: (date: Date) => void;
  organization?: string;
  urlParams?: any[];
  filters?: Filters;
};

const usePaginatedList = ({
  url,
  listSize,
  setLoading,
  setStartDate,
  organization,
  urlParams,
  filters
}: UsePaginatedListParameters) => {
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [total, setTotal] = useState(0);
  const [total2, setTotal2] = useState(0);

  const [values, setValues] = useState<any[]>([]);

  const filtersStructure: any = {
    start_date: filters?.startDate,
    end_date: filters?.endDate
    // filter_by: `start_date:rd[${filters?.startDate},${filters?.endDate}]$end_date:rd[${filters?.startDate},${filters?.endDate}]`
  };

  if (filters?.inputTextList) {
    filters.inputTextList.forEach((inputText) => {
      filtersStructure[inputText.name] = inputText.value;
    });
  }
  // Return object of parameters
  const parseParams = (urlParams: any[] | undefined) => {
    const commonParams = { page, size: listSize, ...filtersStructure };

    const urlSearchParams = new URLSearchParams(url.split('?')[1]);

    // add url params to common params
    if (urlSearchParams) {
      urlSearchParams.forEach((value, key) => {
        commonParams[key] = value;
      });
    }
    // if (commonParams.filter_by) {
    //   commonParams.filter_by += `$start_date:rd[${filters?.startDate},${filters?.endDate}]$end_date:rd[${filters?.startDate},${filters?.endDate}]`;
    // } else {
    //   commonParams.filter_by = `start_date:rd[${filters?.startDate},${filters?.endDate}]$end_date:rd[${filters?.startDate},${filters?.endDate}]`;
    // }

    if (!urlParams) return commonParams;

    return urlParams.reduce(
      (previousValue, currentValue) => ({ ...previousValue, ...currentValue }),
      commonParams
    );
  };

  // FETCH THE DATA
  const fetchData = async () => {
    try {
      if (!organization) {
        return;
      }

      setLoading(true);

      const parameters = parseParams(urlParams);

      parameters.page = 1;
      const response = await apiFetch(
        'GET',
        url.split('?')[0],
        filtersStructure,
        {
          'x-organization-id': organization
        },
        parameters
      );

      setLoading(false);
      const newValues = response.data.items;
      setValues(response.data.items);

      const valuesWithDateDefined = newValues.filter((value: any) => {
        return (
          value?.start_date != null || value?.created_at != null || value?.register_date != null
        );
      });

      if (valuesWithDateDefined[0]?.start_date && setStartDate) {
        setStartDate(new Date(valuesWithDateDefined[valuesWithDateDefined.length - 1].start_date));
      }

      if (valuesWithDateDefined[0]?.created_at && setStartDate) {
        setStartDate(new Date(valuesWithDateDefined[valuesWithDateDefined.length - 1].created_at));
      }

      if (valuesWithDateDefined[0]?.register_date && setStartDate) {
        setStartDate(
          new Date(valuesWithDateDefined[valuesWithDateDefined.length - 1].register_date)
        );
      }

      setPage(2);
      // Only update total if there are no values in filters.inputTextList
      let modifyTotal = true;
      filters?.inputTextList?.forEach((inputText) => {
        if (inputText.value) {
          modifyTotal = false;
          return;
        }
      });
      if (modifyTotal) setTotal(response.data.total);
      if (response.data.total2) {
        setTotal2(response.data.total2);
      }

      setHasMore(newValues.length < response.data.total);
    } catch (err) {
      setLoading(false);
      setPage(1);
      setTotal(0);
      setHasMore(false);
    }
  };

  // FETCH MORE DATA
  const fetchMoreData = async () => {
    try {
      if (!organization) {
        return;
      }
      setLoading(true);

      const parameters = parseParams(urlParams);
      const response = await apiFetch(
        'GET',
        url,
        filtersStructure,
        {
          'x-organization-id': organization
        },
        parameters
      );

      setLoading(false);
      const newValues = [...values, ...response.data.items];
      setValues(newValues);
      setPage((prev) => prev + 1);
      // Only update total if there are no values in filters.inputTextList
      let modifyTotal = true;
      filters?.inputTextList?.forEach((inputText) => {
        if (inputText.value) {
          modifyTotal = false;
          return;
        }
      });
      if (modifyTotal) setTotal(response.data.total);
      if (response.data.total2) {
        setTotal2(response.data.total2);
      }
      setHasMore(newValues.length < response.data.total);
    } catch (err) {
      setLoading(false);
      setPage(1);
      setTotal(0);
      setHasMore(false);
    }
  };

  return {
    fetchData,
    fetchMoreData,
    hasMore,
    values,
    setValues,
    total,
    setTotal,
    total2,
    setTotal2,
    page
  };
};

export default usePaginatedList;
