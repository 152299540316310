import { createContext, useContext, useState } from 'react';

type DataContext<T extends object> = {
  data: T | null;
  setData: React.Dispatch<React.SetStateAction<T | null>>;
};

export const createDataContext = <T extends object>() => {
  const DataContext = createContext<DataContext<T> | null>(null);

  const useDataContext = () => {
    const context = useContext(DataContext);

    if (!context) {
      throw new Error('useDataContext must be used within a DataContextProvider');
    }

    return context;
  };

  type DataProviderProps = {
    children: React.ReactNode;
  };

  const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
    const [data, setData] = useState<T | null>(null);

    return <DataContext.Provider value={{ data, setData }}>{children}</DataContext.Provider>;
  };

  return [DataProvider, useDataContext] as const;
};
