import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setGroupings, switchGrouping } from '../../../actions/auth';
import { SIZE_PAGINATION_BIG } from '../../../constants';
import { ROUTES } from '../../../constants/routes';
import { FilterDatesContext } from '../../../context/filterDatesContext';
import { UserContext } from '../../../context/userContext';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import { getTotalImpactsByMonth } from '../../../services/api/total_impacts';
import apiFetch from '../../../utils/apiFetch';
import { checkFreePlan } from '../../../utils/checkFreePlan';
import { convertStringToDate } from '../../../utils/convertDates';
import NoDataLanding from '../../layout/NoData/NoDataLanding';
import CompanyEnvironmentImpact from '../dashboardCommon/companyEnviromentImpact/CompanyEnvironmentImpact';
import ScopeEnvironmentImpact from '../dashboardCommon/scopeEnviromentImpact/ScopeEnviromentImpact';
import './styles.scss';

const DashboardGrouping = () => {
  const { t } = useTranslation();
  const { startDate, endDate } = useContext(FilterDatesContext);
  const selectedOrganization = useSelectedOrganization();
  const freePlanTrial = checkFreePlan(selectedOrganization);
  const user = useContext(UserContext);

  const groupingId = user?.selectedGrouping;

  const dispatch = useDispatch();

  const [dataScopes, setDataScopes] = useState<ScopeData[]>([]);
  const [dataCategories, setDataCategories] = useState<CategoryData[]>([]);

  const [loading, setLoading] = useState(true);

  const groupByTotalImpacts = (
    totalImpactsValue: TotalImpactPerMonth[],
    scope = true,
    category = true
  ) => {
    const groupByScope = [
      { scope: 1, total_emissions: 0 },
      { scope: 2, total_emissions: 0 },
      { scope: 3, total_emissions: 0 }
    ];

    const groupByCategory: {
      total_emissions: number;
      category: string;
      scope: number;
    }[] = [];

    totalImpactsValue.forEach((impact: TotalImpactPerMonth) => {
      if (scope) {
        const indexFoundGroupByScope = groupByScope.findIndex(
          (elem: any) => elem.scope === impact.scope
        );
        if (indexFoundGroupByScope > -1) {
          groupByScope[indexFoundGroupByScope].total_emissions += impact.emissions_per_month;
        }
      }
      if (category) {
        const indexFoundGroupByCategory = groupByCategory.findIndex(
          (elem: any) => elem.category === impact.category
        );
        if (indexFoundGroupByCategory > -1) {
          groupByCategory[indexFoundGroupByCategory].total_emissions += impact.emissions_per_month;
        } else {
          groupByCategory.push({
            category: impact.category,
            total_emissions: impact.emissions_per_month,
            scope: impact.scope
          });
        }
      }
    });
    if (scope) {
      setDataScopes(groupByScope);
    }
    if (category) {
      setDataCategories(groupByCategory);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const data = await getTotalImpactsByMonth(
      startDate,
      endDate,
      selectedOrganization?.id ?? '',
      groupingId
    );

    setLoading(false);
    if (!data) return;

    groupByTotalImpacts(data, true, true);
  };

  useEffect(() => {
    if (!groupingId) return;
    fetchData();
  }, [groupingId, startDate, endDate]);

  const fetchGroupings = async () => {
    const response = await apiFetch(
      'GET',
      '/data_groups',
      null,
      {},
      {
        size: SIZE_PAGINATION_BIG
      }
    );
    const data = response.data;

    if (!data || !data.items || data.items.length === 0) return;

    dispatch(switchGrouping(data.items[0].id));
    dispatch(setGroupings(data.items));
  };

  useEffect(() => {
    fetchGroupings();
  }, []);

  const showLanding = !selectedOrganization || groupingId === 'search';

  if (showLanding && !loading) {
    return <NoDataLanding link={ROUTES.CUSTOM_GROUPING} textButton={t('dashboard.toGrouping')} />;
  }

  return (
    <div
      className='dashboard__body'
      style={{ gridTemplateRows: `repeat(${1}, min(calc(50vh - 4.25rem), 30rem))` }}>
      {selectedOrganization && (
        <CompanyEnvironmentImpact
          data={dataScopes}
          organization={selectedOrganization}
          startDate={convertStringToDate(startDate)}
          endDate={convertStringToDate(endDate)}
          loading={loading}
        />
      )}
      <ScopeEnvironmentImpact
        data={dataCategories}
        disabled={freePlanTrial}
        loading={loading}
        freeTrial={freePlanTrial}
      />
    </div>
  );
};

export default DashboardGrouping;
