import { useTranslation } from 'react-i18next';
import NuvoImporter from '../../../ui/nuvoImporter/NuvoImporter';
import {
  ColumnAPI,
  ColumnHookCallback,
  LanguageType,
  OnEntryChange,
  OnEntryInit,
  RejectSubmitResult,
  ResultValues,
  SettingsAPI
} from 'nuvo-react';
import { basicNuvoStyle } from '../../../../styles/nuvo';
import useNuvoFileName from '../../../../customHooks/useNuvoFileName';
import useErrors from '../../../../customHooks/useErrors';
import { convertStringToDateBackend } from '../../../../utils/convertDates';
import { transformNuvoResultsIntoCsv, validateDateFields } from '../../../../utils/nuvo';
import { getPresignedUrlPurchases } from '../../../../services/api/purchases';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { uploadFilePresignedUrl } from '../../../../services/api/aws';
import { postNuvoAnalytics } from '../../../../services/api/nuvoAnalytics';
import { useDispatch } from 'react-redux';
import { startPurchaseLoader, stopPurchaseLoader } from '../../../../actions/purchasesLoader';
import { useNuvoCountries } from '../../../../customHooks/useNuvoCountries';
import useNuvoButton from '../../../../customHooks/useNuvoButton';
import { useFeatureFlags } from '../../../../customHooks/useFeatureFlags';

const TIMEOUT_IN_MS = 20000;

const recycledHook: ColumnHookCallback = (values) =>
  values.map(([item, index]) => {
    return [
      {
        value: item !== undefined ? item : false
      },
      index
    ];
  });

async function parseNuvoResults(results: ResultValues, fileName: string, category: string) {
  const content = transformNuvoResultsIntoCsv(results);

  const finalFileName = `${fileName || category}.csv`;

  // transform content to File
  const file = new File([content], finalFileName, { type: 'text/csv' });

  return { finalFileName, file };
}

const treatString = (value: string) => {
  if (value.includes(',')) {
    return value.replaceAll(',', '.');
  }
  return value;
};
const treatRest = <T extends object>(obj: T) => {
  const newObj: Record<string, any> = {};

  for (const key in obj) {
    const value = obj[key];

    if (typeof value === 'string') {
      newObj[key] = treatString(value);
      continue;
    }

    newObj[key] = value;
  }

  return newObj;
};

const nuvoAdapter = (data: ResultValues) => {
  return data.map((item) => {
    const { euro_gross_quantity, country, ...rest } = item;

    if (typeof euro_gross_quantity === 'object' && !Array.isArray(euro_gross_quantity)) {
      return {
        ...treatRest(rest),
        euro_gross_quantity: euro_gross_quantity?.value,
        supplier_country: country,
        origin_country: country
      };
    }

    return {
      ...treatRest(rest),
      euro_gross_quantity,
      supplier_country: country,
      origin_country: country
    };
  });
};

const CATEGORY = 'purchases';

type Props = {
  onUpload?: (results: ResultValues) => void;
};
export const Nuvo: React.FC<Props> = ({ onUpload }) => {
  const { t, i18n } = useTranslation();

  const organization = useSelectedOrganization();
  const flags = useFeatureFlags();

  const { fileName, handleGetFileName, handleExit } = useNuvoFileName();
  useNuvoButton({ onClick: handleGetFileName });

  const ERRORS = useErrors();

  const { countriesNuvo } = useNuvoCountries();

  const dispatch = useDispatch();

  const nuvoError = new RejectSubmitResult(
    ERRORS.NUVO.GENERAL_ERROR_TITLE,
    ERRORS.NUVO.GENERAL_ERROR_MESSAGE
  );

  const nuvoErrorFounded = new RejectSubmitResult(
    ERRORS.NUVO.ERRORS_FOUNDED_TITLE,
    ERRORS.NUVO.ERRORS_FOUNDED_MESSAGE
  );

  const columns: ColumnAPI[] = [
    {
      key: 'purchased_product',
      label: t('purchases.nuvo.columns.productName'),
      columnType: 'string',
      validations: [
        {
          validate: 'required'
        }
      ],

      description: t('purchases.nuvo.descriptions.productName'),
      alternativeMatches: [
        'product',
        'product name',
        'purchased product',
        'product_name',
        'purchased_product',
        'produto',
        'nome do produto',
        'produto comprado',
        'produto_comprado',
        'nome_do_produto',
        'producto',
        'nombre del producto',
        'producto comprado',
        'producto_comprado',
        'nombre_del_producto'
      ]
    },
    {
      key: 'purchase_date',
      label: t('purchases.nuvo.columns.purchaseDate'),
      columnType: 'date',
      validations: [
        {
          validate: 'required'
        }
      ],
      outputFormat: 'YYYY-MM-DD',
      description: t('purchases.nuvo.descriptions.purchaseDate'),
      alternativeMatches: [
        'date',
        'purchase date',
        'fecha de compra',
        'data da compra',
        'data de compra',
        'purchase_date',
        'fecha_de_compra',
        'data_da_compra',
        'data_de_compra'
      ]
    },
    {
      key: 'business_name',
      label: t('purchases.nuvo.columns.businessName'),
      columnType: 'string',
      validations: [
        {
          validate: 'required'
        }
      ],
      description: t('purchases.nuvo.descriptions.businessName'),
      alternativeMatches: ['supplier', 'provider', 'proveedor', 'fornecedor']
    },
    {
      key: 'country',
      label: t('purchases.nuvo.columns.supplierCountry'),
      columnType: 'category',
      dropdownOptions: countriesNuvo,
      validations: [
        {
          validate: 'required',
          errorMessage: ERRORS.NUVO.COUNTRY_NOT_FOUND
        }
      ],
      description: t('purchases.nuvo.descriptions.supplierCountry'),
      alternativeMatches: [
        'country',
        'country of origin',
        'country of purchase',
        'country of origin of purchase',
        'país de origen',
        'país de compra',
        'país de origen de la compra',
        'pais de origen',
        'pais de compra',
        'pais de origen de la compra',
        'país de origem',
        'país de compra',
        'país de origem da compra',
        'pais de origem',
        'pais de origem da compra'
      ]
    },
    {
      key: 'euro_gross_quantity',
      label: t('purchases.nuvo.columns.euroGrossQuantity'),
      columnType: 'currency_eur',
      validations: [
        {
          validate: 'required'
        },
        {
          validate: 'regex',
          regex: '^[0-9]+(.[0-9]{1,2})?$'
        }
      ],
      description: t('purchases.nuvo.descriptions.euroGrossQuantity'),
      alternativeMatches: [
        'euros',
        'price',
        'quantity',
        'euro_gross_quantity',
        'euro gross quantity',
        'euro gross',
        'euro_gross',
        'bruto',
        'euro bruto',
        'euro_bruto',
        'precio',
        'euro precio',
        'euro_precio',
        'cantidad',
        'euro cantidad',
        'euro_cantidad',
        'quantidade',
        'euro quantidade',
        'euro_quantidade',
        'preço',
        'euro preço',
        'euro_preço'
      ]
    },
    {
      key: 'recycled',
      label: t('purchases.nuvo.columns.recycled'),
      columnType: 'boolean',
      description: t('purchases.nuvo.descriptions.recycled'),
      alternativeMatches: [
        'reciclado',
        'reciclada',
        'reciclados',
        'recicladas',
        'recycled',
        'reused',
        'reutilizado'
      ]
    }
    // {
    //   key: 'code',
    //   label: t('purchases.nuvo.columns.code'),
    //   columnType: 'string',
    //   description: t('purchases.nuvo.descriptions.code'),
    //   alternativeMatches: ['code', 'codigo'],
    //   validations: [
    //     {
    //       // regex number with 4 digits
    //       validate: 'regex',
    //       regex: '^[0-9]{4}$'
    //     },
    //     {
    //       validate: 'required_with',
    //       columns: ['framework']
    //     }
    //   ]
    // },
    // {
    //   key: 'framework',
    //   label: t('purchases.nuvo.columns.framework'),
    //   columnType: 'category',
    //   description: t('purchases.nuvo.descriptions.framework'),
    //   alternativeMatches: ['framework'],
    //   dropdownOptions: [
    //     {
    //       type: 'string',
    //       label: 'us_sic_v87',
    //       value: 'us_sic_v87'
    //     },
    //     {
    //       type: 'string',
    //       label: 'cnae_2009',
    //       value: 'cnae_2009'
    //     },
    //     {
    //       type: 'string',
    //       label: 'nace_rev_2',
    //       value: 'nace_rev_2'
    //     }
    //   ],
    //   validations: [
    //     {
    //       validate: 'required_with',
    //       columns: ['code']
    //     }
    //   ]
    // }
  ];

  if (flags?.uploadOfficialSupplier) {
    columns.push({
      key: 'duns',
      label: t('purchases.nuvo.columns.duns'),
      columnType: 'int',
      description: t('purchases.nuvo.descriptions.duns'),
      alternativeMatches: ['duns']
    });
  }

  const settings: SettingsAPI = {
    multipleFileUpload: true,
    language: i18n.resolvedLanguage as LanguageType,
    disableTemplateDropdowns: true,
    style: basicNuvoStyle,
    automaticHeaderDetection: true,
    maxEntries: 700_000,
    identifier: 'purchases_template_Dcycle', // Template file name
    columns
  };

  const validateRow: OnEntryInit = (row) => {
    const date = row.purchase_date as string;

    const errors = {} as any;

    const currency = row.euro_gross_quantity;

    if (!row || !date) return;

    if (convertStringToDateBackend(date) > new Date()) {
      errors.purchase_date = {
        value: date,
        info: [
          {
            level: 'error',
            message: t('purchases.nuvo.errors.purchaseDate')
          }
        ]
      };
    }

    const minYearErrors = validateDateFields(['purchase_date'])(row);

    for (const key in minYearErrors) {
      errors[key] = minYearErrors[key];
    }

    if (typeof currency === 'number' && currency < 0) {
      errors.euro_gross_quantity = {
        value: currency,
        info: [
          {
            level: 'error',
            message: 'Value must be positive'
          }
        ]
      };
    }

    return errors;
  };

  const onEntryChange: OnEntryChange = (rows) => {
    return rows
      .filter((row) => Object.keys(validateRow(row.data, row.rowIndex) ?? {}).length > 0)
      .map((row) => {
        return {
          rowIndex: row.rowIndex,
          data: {
            ...row.data,
            ...validateRow(row.data, row.rowIndex)
          }
        };
      });
  };

  return (
    <NuvoImporter
      btnI18nKey='purchases.uploadFile'
      settings={settings}
      onCancel={handleExit}
      columnHooks={{
        recycled: recycledHook
      }}
      onEntryInit={validateRow}
      onEntryChange={onEntryChange}
      onResults={async (results, errors, complete) => {
        if (errors.length > 0) {
          complete(nuvoErrorFounded);
          return;
        }

        if (results.length <= 0) {
          complete(nuvoError);
          return;
        }

        onUpload?.(results);

        const adaptedResults = nuvoAdapter(results);

        const { finalFileName, file } = await parseNuvoResults(adaptedResults, fileName, CATEGORY);

        if (!organization) return complete(nuvoError);

        const data = await getPresignedUrlPurchases(finalFileName, organization.id);

        if (data?.response?.status >= 400) return complete(nuvoError);

        const response = await uploadFilePresignedUrl(file, data?.upload_url);

        if (!response) return complete(nuvoError);

        // analytics
        await postNuvoAnalytics({
          numberOfRows: results.length,
          fileName: finalFileName,
          category: CATEGORY
        });

        startPurchaseLoader(dispatch);

        setTimeout(() => {
          stopPurchaseLoader(dispatch);
        }, TIMEOUT_IN_MS);

        complete();
      }}
    />
  );
};
