import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../actions/notification';
import { useFeatureFlags } from '../../../customHooks/useFeatureFlags';
import useGetBase64 from '../../../customHooks/useGetBase64';
import useOnChangeValue from '../../../customHooks/useOnChangeValue';
import { uploadFilePresignedUrl } from '../../../services/api/aws';
import { getPresignedUrlVehiclesConsumptions } from '../../../services/api/vehicleConsumptions';

import checkFormErrors from '../../../utils/checkFormErrors';
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb';
import MainPageLayout from '../../layout/mainPageLayout/MainPageLayout';
import Button from '../../ui/button/Button';
import FormButtonSection from '../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementFull from '../../ui/formComponents/formElementFull/FormElementFull';
import FormWrapper from '../../ui/formComponents/formWrapper/FormWrapper';
import InputFileSingle from '../../ui/formComponents/inputFile/InputFileSingle';
import FormText from '../../ui/formComponents2/formInputs/formText/FormText';
import Modal from '../../ui/modal/Modal';
import DeleteVehicleConsumption from './deleteVehicleConsumption/DeleteVehicleConsumption';
import GetVehicleConsumptionError from './getVehicleConsumptionError/GetVehicleConsumptionError';
import './styles.scss';
type FormData = {
  organizationId: string;
  file: File | null;
  errors: ErrorType[];
};

const VehicleConsumptionUpload = () => {
  const { t } = useTranslation();

  const flags = useFeatureFlags();
  const [formData, setFormData] = useState<FormData>({
    organizationId: '',
    file: null,
    errors: []
  });
  const [loadingButton, setLoadingButton] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showGetVehicleConsumptionWithErrors, setShowGetVehicleConsumptionWithErrors] =
    useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const { fileBase64 } = useGetBase64(formData.file as File);

  const dispatch = useDispatch();

  const handleErrors = () => {
    const newErrors: ErrorType[] = checkFormErrors(formData, []);

    if (newErrors.length === 0) return;

    setFormData({
      ...formData,
      errors: newErrors
    });

    return newErrors;
  };

  const handleSubmit = async () => {
    // Call api to upload CSV file after converting to base64
    if (handleErrors()) return;
    if (!formData.file || !fileBase64) {
      return;
    }
    setLoadingButton(true);

    const data = await getPresignedUrlVehiclesConsumptions({
      file_name: formData.file?.name ?? 'vehicle_consumption.csv',
      base64_file: fileBase64 as string
    });

    const response = await uploadFilePresignedUrl(formData.file, data.upload_url);

    setLoadingButton(false);
    if (!response) {
      dispatch(setNotification(t('error.somethingWentWrong')));
      setLoadingButton(false);
      return;
    }
    dispatch(setNotification(t('vehicleConsumptionUpload.success')));
  };

  const downloadTemplateCsv = async () => {
    // Download the csv file in public/files/vehicleConsumption/vehicle_consumptions_template_Dcycle.csv
    const link = document.createElement('a');
    link.href =
      process.env.PUBLIC_URL + '/files/vehicleConsumption/vehicle_consumptions_template_Dcycle.csv';
    link.setAttribute('download', 'vehicle_consumption_template.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleDelete = async () => {
    // Call api to upload CSV file after converting to base64
    const errors = checkFormErrors(formData, formData.errors, ['file']);
    if (errors.length > 0) {
      setFormData({
        ...formData,
        errors
      });
      return;
    }
    setShowDeleteModal(true);
  };

  const handleShowGetVehicleConsumptionWithErrors = () => {
    setShowGetVehicleConsumptionWithErrors(true);
  };

  const handleHideModal = () => {
    setShowDeleteModal(false);
    setShowGetVehicleConsumptionWithErrors(false);
  };

  if (!flags?.businessTravelUpload) {
    return null;
  }
  return (
    <>
      <MainPageLayout
        sectionTitle={t('vehicleConsumptionUpload.title')}
        title={t('vehicleConsumptionUpload.createTemplateTitle')}
        description={t('vehicleConsumptionUpload.createTemplateStartDescription')}
        breadcrumb={<Breadcrumb />}
        buttons={
          <>
            <Button
              lookAndFeel='primary'
              text={t('vehicleConsumptionUpload.createTemplate')}
              onClick={downloadTemplateCsv}
              size='small'
            />
            <Button
              lookAndFeel='secondary'
              onClick={handleShowGetVehicleConsumptionWithErrors}
              text={t('vehicleConsumptionUpload.getVehicleConsumptionWithErrors')}
              size='small'
            />
          </>
        }>
        <div className='vehicleConsumption-upload card'>
          <FormWrapper>
            <FormText
              icon={'/images/icons/organization.svg'}
              placeholder={t('vehicleConsumptionUpload.organizationId')}
              label={t('vehicleConsumptionUpload.organizationId')}
              value={formData.organizationId}
              onChange={onChangeValue('organizationId')}
              error={formData.errors.find((error) => error.error === 'organizationId')}
            />
            <FormElementFull>
              <InputFileSingle
                handleFile={onChangeValue('file')}
                buttonText={t('vehicleConsumptionUpload.upload')}
                labelText={t('vehicleConsumptionUpload.uploadLabel')}
                fileName={formData.file?.name || ''}
                allowedTypes={['text/csv']}
                error={formData.errors.find((error) => error.error === 'file')}
              />
            </FormElementFull>
          </FormWrapper>
          <FormButtonSection>
            <Button
              lookAndFeel='primary'
              text={t('vehicleConsumptionUpload.save')}
              onClick={handleSubmit}
              loading={loadingButton}
            />
            <Button
              lookAndFeel='secondary'
              text={t('vehicleConsumptionUpload.delete')}
              onClick={handleDelete}
            />
          </FormButtonSection>
        </div>
      </MainPageLayout>
      <Modal show={showDeleteModal} onClose={handleHideModal}>
        <DeleteVehicleConsumption
          organizationId={formData.organizationId}
          deleteCallback={handleHideModal}
        />
      </Modal>
      <Modal show={showGetVehicleConsumptionWithErrors} onClose={handleHideModal}>
        <GetVehicleConsumptionError />
      </Modal>
    </>
  );
};

export default VehicleConsumptionUpload;
