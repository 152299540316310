import { useTranslation } from 'react-i18next';
import './styles.scss';

interface Props {
  width?: string;
  darkBackground?: boolean;
}

export const SfcLogo = ({ width, darkBackground }: Props) => {
  const { t } = useTranslation();
  return (
    <div className='sfc-logo-container'>
      <p>{t('logistics.sfcCertified')}</p>
      <img
        className='sfc-logo'
        src={darkBackground ? '/images/sfc-white-logo.svg' : '/images/sfc-logo.svg'}
        width={width ? width : '10%'}
      />
    </div>
  );
};
