import Label from '../../label/Label';
import Icon from '../../icon/Icon';
import { IconName } from '../../../../types/utils/iconName';

type Props = React.ComponentProps<typeof Label> & {
  icon?: IconName;
};

const WarningLabel = ({ children, icon = 'warning', ...rest }: Props) => {
  return (
    <Label {...rest} lookAndFeel='warning'>
      <div className='label-loading-wrapper error-loading-wrapper'>
        <Icon size='medium' icon={icon} color='warning' /> {children}
      </div>
    </Label>
  );
};

export default WarningLabel;
