import ModalDS from '../../../../../ui/modal/Modal';
import FormText from '../../../../../ui/formComponents2/formInputs/formText/FormText';
import Button from '../../../../../ui/button/Button';
import { useTranslation } from 'react-i18next';
import { patchPrimaryCode } from '../../../../../../services/api/primaryCode';
import { useState } from 'react';
import {
  IPrimaryCodeBackend,
  IPrimaryCodeFrontend
} from '../../../../../../types/entities/primaryCode';
import InputWrapper from '../../../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import { useDcycleProducts } from '../hooks';
import SlideToggle from '../../../../../ui/formComponents/slideToggle/SlideToggle';
import descriptionsPrimaryCode from '../../../../../../constants/json/sic_description.json';
import Select from '../../../../../ui/formComponents2/select/Select';

type Props = {
  primaryCodeToEdit: IPrimaryCodeFrontend | null;
  setPrimaryCodeToEdit: React.Dispatch<React.SetStateAction<IPrimaryCodeFrontend | null>>;
  editElement: (element: IPrimaryCodeBackend) => void;
};

const Modal = ({ editElement, primaryCodeToEdit, setPrimaryCodeToEdit }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'codeManagement.primaryCode.modal' });

  const [dcycleProduct, setDcycleProduct] = useState(primaryCodeToEdit?.dcycleProduct);
  const [hasSuggestions, setHasSuggestions] = useState(true);

  const { data: dcycleProducts } = useDcycleProducts({
    code: primaryCodeToEdit?.code,
    hasSuggestions
  });

  const handleClose = () => {
    setPrimaryCodeToEdit(null);
  };

  const handleAccept = async () => {
    if (!primaryCodeToEdit?.id || !dcycleProduct) return;

    try {
      const response = await patchPrimaryCode(primaryCodeToEdit?.id, {
        id: primaryCodeToEdit.id,
        dcycleProduct,
        description:
          descriptionsPrimaryCode[primaryCodeToEdit.code as keyof typeof descriptionsPrimaryCode] ??
          'Description not found'
      });

      if (!response) {
        throw new Error('Something went wrong, please try again later.');
      }

      editElement(response);

      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  if (!primaryCodeToEdit) {
    return null;
  }

  return (
    <ModalDS show={Boolean(primaryCodeToEdit)} onClose={handleClose}>
      <>
        <h3 className='headline3-font'>{t('title')}</h3>
        <p className='subtitle3-font'>{t('subTitle')}</p>
        <div className='grid-cols-3 gap-x-4 gap-y-8'>
          <div className='col-span-3'>
            <FormText
              label={t('description')}
              disabled
              value={
                descriptionsPrimaryCode[
                  primaryCodeToEdit?.code as keyof typeof descriptionsPrimaryCode
                ] ?? 'Description not found'
              }
            />
          </div>
          <FormText label={t('type')} disabled value={primaryCodeToEdit?.framework} />
          <div className='col-span-2'>
            <FormText label={t('code')} disabled value={primaryCodeToEdit?.code} />
          </div>
          <div className='col-span-3 flex-col gap-y-2'>
            <InputWrapper label={t('dcycle-product')}>
              <Select
                value={
                  dcycleProduct || {
                    id: '',
                    name: ''
                  }
                }
                placeholder={t('select-placeholder')}
                options={dcycleProducts}
                onChange={setDcycleProduct}
              />
            </InputWrapper>
            <SlideToggle
              label={t('suggestions')}
              checked={hasSuggestions}
              setChecked={setHasSuggestions}
            />
          </div>
          <div className='col-span-3'>
            <Button
              size='medium'
              lookAndFeel='primary'
              onClick={handleAccept}
              disabled={!dcycleProduct}>
              {t('accept')}
            </Button>
          </div>
        </div>
      </>
    </ModalDS>
  );
};

export { Modal };
