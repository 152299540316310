import apiFetch from '../../utils/apiFetch';
import { QueryParams, getUrl } from '../../utils/url';

const URL = '/dcycle-products';

export const getDcycleProducts =
  (code: string, hasSuggestions: boolean) => async (page: number) => {
    if (!code) {
      return undefined;
    }

    const queryParams: QueryParams[] = [
      {
        key: 'page',
        value: page
      }
    ];

    if (code && hasSuggestions) {
      queryParams.push({
        key: 'code',
        value: code
      });
    }

    const url = getUrl(URL, {
      queryParams
    });

    try {
      const response = await apiFetch('GET', url);

      return response.data as Promise<Pagination<IDcycleProductBackend>>;
    } catch (error) {
      return undefined;
    }
  };
