import { useState } from 'react';
import { ColorPaletteProvider } from '../components/ColorPaletteContext/ColorPaletteContext';
import { LCADoughnutChart } from '../components/LCADoughnutChart/LCADoughnut';
import { ColorPaletteKey } from '../types/ColorPalette';
import { FrontLCAComponent, FrontLCAMethodology, LCAFoldersResponse } from '../types/LCADetail';
import { LCAMethodologyTable } from '../components/LCAMethodologyTable/LCAMethodologyTable';
import TotalLegendV2 from '../../../../ui/totalLegend/TotalLegendV2';
import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import { LCAMethodologyProvider } from '../components/LCAMethodologyContext/LCAMethodologyContext';
import { LCAComponentTable } from '../components/LCAComponentTable/LCAComponentTable';
import { getPhaseTitle } from './utils';
import { usePhase } from './hooks';
import Breadcrumb from '../../../../layout/breadcrumb/Breadcrumb';
import { LCA_IMPACT_CATEGORIES_TRANSLATION_KEYS } from '../../constants';
import { LCAFoldersTable } from '../components/LCAFoldersTable/LCAFoldersTable';
import { useLCAData } from '../hooks/useLCAData';
import { useTranslation } from 'react-i18next';
import { formatLCAUnit } from '../utils/formatLCAUnit';
import LoaderTables from '../../../../ui/loaders/loaderTables/LoaderTables';
import { LCADetailFacade } from '../components/LCADetailFacade';
import { BlueTag } from '../../../../ui/blueTag/BlueTag';
import { formatNumberToDecimalNonZero } from '../../../../../utils/numberToDecimal';
import { AZKOYEN_OLD_IDS } from '../constants';
import { useACVId } from '../../../Scenarios/hooks/useACVId';

export const EnvironmentalImpacts = () => {
  const [methodology, setMethodology] = useState<FrontLCAMethodology | undefined>(undefined);
  const [lcaFolder, setLcaFolder] = useState<keyof LCAFoldersResponse | undefined>(undefined);
  const [lcaComponentsUsed, setLcaComponentsUsed] = useState<FrontLCAComponent[]>([]);
  const [colorPalette, setColorPalette] = useState<ColorPaletteKey>();
  const { acv_id } = useACVId();

  const { t: translateUnit } = useTranslation('translation', { keyPrefix: 'units_short' });

  const lcaComponentBeingUsed =
    lcaComponentsUsed.length > 0 ? lcaComponentsUsed[lcaComponentsUsed.length - 1] : undefined;

  const phase = usePhase(methodology, lcaFolder, lcaComponentBeingUsed);

  const { t } = useLCATranslation();

  const { data, percentage, isLoading, currentTotal } = useLCAData({
    methodology,
    lcaComponentsUsed,
    lcaFolder
  });

  const handleSelectSection = (index: number, component: FrontLCAComponent) => {
    if (!lcaFolder) {
      setLcaFolder(component.id as keyof LCAFoldersResponse);
      setColorPalette(index);
      return;
    }

    setLcaComponentsUsed((prev) => [...prev, component]);
  };

  const handleSelectFolder = (index: number, folder: keyof LCAFoldersResponse) => {
    setColorPalette(index);
    setLcaFolder(folder);
  };

  const phaseName = lcaComponentBeingUsed?.name ?? t('lcaDetail.totalImpact');

  const phaseTitle = t(
    getPhaseTitle(Boolean(methodology), Boolean(lcaFolder), lcaComponentsUsed.length),
    {
      phase
    }
  );

  const breadCrumbs = [
    {
      key: 'landing',
      value: t('lcaDetail.landingBreadcrumb')
    }
  ];

  if (methodology) {
    breadCrumbs.push({
      key: 'methodology',
      value: t(LCA_IMPACT_CATEGORIES_TRANSLATION_KEYS[methodology.impact_category])
    });
  }

  if (lcaFolder) {
    breadCrumbs.push({
      key: 'lcaFolder',
      value: t(`lcaDetail.${lcaFolder}`)
    });
  }

  for (const component of lcaComponentsUsed) {
    breadCrumbs.push({
      key: component.id,
      value: component.name
    });
  }

  const handleCrumbClick = (key: string) => {
    if (key === 'landing') {
      setLcaComponentsUsed([]);
      setLcaFolder(undefined);
      setColorPalette(undefined);
      setMethodology(undefined);
      return;
    }

    if (key === 'methodology') {
      setLcaFolder(undefined);
      setLcaComponentsUsed([]);
      setColorPalette(undefined);
      return;
    }

    if (key === 'lcaFolder') {
      setLcaComponentsUsed([]);
      return;
    }

    setLcaComponentsUsed((prev) => {
      const index = prev.findIndex((component) => component.id === key);

      if (index === -1) {
        return prev;
      }

      return prev.slice(0, index + 1);
    });
  };

  const doughnutBgColor = data ? 'card-bg-color' : 'bg-neutral-gray-warm-80';

  return (
    <ColorPaletteProvider colorPalette={colorPalette} setColorPalette={setColorPalette}>
      <LCAMethodologyProvider methodology={methodology} setMethodology={setMethodology}>
        <div className='flex-col gap-y-4 h-full overflow-hidden'>
          <div className='flex justify-between mt-2'>
            <Breadcrumb.Custom breadCrumbs={breadCrumbs} handleCrumbClick={handleCrumbClick} />
            {!AZKOYEN_OLD_IDS.includes(acv_id) && <LCADetailFacade />}
          </div>
          <div className='grid-cols-2 gap-x-2 grid-row-full py-4 flex-grow overflow-hidden'>
            <div className='flex-col gap-y-4 card-bg-color card-border-color border-1 border-solid rounded-8 p-8 flex-grow overflow-y-auto'>
              <h2 className='font-body-b1-b m-0 mb-4'>{phaseTitle}</h2>
              {!methodology && <LCAMethodologyTable />}
              {methodology && !lcaFolder && (
                <LCAFoldersTable
                  handleSelectSection={handleSelectFolder}
                  currentTotal={currentTotal}
                />
              )}
              {lcaFolder && (
                <LCAComponentTable
                  data={data}
                  handleSelectSection={handleSelectSection}
                  currentTotal={currentTotal}
                />
              )}
              {isLoading && <LoaderTables />}
            </div>
            <div
              className={`${doughnutBgColor} p-8 rounded-8 card-border-color border-1 border-solid flex-grow overflow-hidden border-box flex-col gap-y-4`}>
              <div className='flex justify-between items-center pb-3 gap-y-3 h-6'>
                {methodology && (
                  <>
                    <div className='flex-col gap-y-2'>
                      <span className='font-body-b1-b'>{phaseName}</span>
                      <BlueTag>
                        {`${formatNumberToDecimalNonZero(percentage, 1)}% ${t(
                          'lcaDetail.percentageOfTotal'
                        )}`}
                      </BlueTag>
                    </div>

                    <TotalLegendV2
                      totalLabel={t('lcaDetail.totalImpact')}
                      totalElements={[
                        {
                          value: Number(methodology.impact),
                          label: formatLCAUnit(
                            translateUnit(methodology.unit.name),
                            methodology.category
                          )
                        }
                      ]}
                    />
                  </>
                )}
              </div>
              <div className='flex-grow'>
                <LCADoughnutChart
                  data={data}
                  onSelectSection={handleSelectSection}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </LCAMethodologyProvider>
    </ColorPaletteProvider>
  );
};
