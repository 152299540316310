import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../../../context/userContext';
import { getPurchases } from '../../../../../../services/api/purchases';

export const useTotal = () => {
  const [total, setTotal] = useState<number>();
  const [loading, setLoading] = useState(true);

  const user = useContext(UserContext);

  const orgId = user?.organizations?.find((org) => org.id === user.selectedOrganization)?.id;

  useEffect(() => {
    if (orgId) {
      setLoading(true);
      const response = getPurchases();

      response.then((res) => {
        if (res) {
          setTotal(res.total);
        }
        setLoading(false);
      });
    } else {
      setTotal(undefined);
    }
  }, [orgId]);

  return {
    total,
    loading,
    setTotal
  };
};
