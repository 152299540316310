import { getPurchaseSuppliers } from '../../../../../../services/api/purchaseSuppliers';
import {
  IPurchaseSupplierBackend,
  IPurchaseSupplierRest
} from '../../../../../../types/purchaseSupplier';
import { IFilter, QueryParams } from '../../../../../../utils/url';
import useFetchInfiniteList from '../../../../../ui/infiniteList/hooks/useFetchInfiniteList';
import { getPurchaseSuppliersAdapter } from '../adapters/getPurchaseSupplierAdapter';

type Params = {
  filters: IFilter[];
  isInformaData?: boolean;
  refreshSupplierBadge?: () => void;
};

const makeFetch =
  ({ filters, refreshSupplierBadge, isInformaData }: Params) =>
  async (page: number) => {
    const queryParams: QueryParams[] = [
      {
        key: 'page',
        value: page
      }
    ];

    if (isInformaData) {
      queryParams.push({
        key: 'is_informa_data',
        value: true
      });
    }
    const response = await getPurchaseSuppliers({ queryParams, filters });

    refreshSupplierBadge?.();

    return response;
  };

export const useGetSuppliers = ({ filters, refreshSupplierBadge, isInformaData }: Params) => {
  const {
    data,
    fetchData,
    firstLoading,
    loading,
    editElement,
    total,
    rest,
    setTotal,
    refreshData
  } = useFetchInfiniteList<IPurchaseSupplierBackend, IPurchaseSupplierRest>(
    makeFetch({ filters, refreshSupplierBadge, isInformaData }),
    [JSON.stringify(filters), isInformaData]
  );

  return {
    data: getPurchaseSuppliersAdapter(data),
    fetchData,
    firstLoading,
    loading,
    editElement,
    total,
    total2: rest?.total2,
    setTotal,
    refreshData
  };
};
