import { useTranslation } from 'react-i18next';
import { useFeatureFlags } from '../../../../../../customHooks/useFeatureFlags';
import { useContext } from 'react';
import { UserContext } from '../../../../../../context/userContext';
import { useSupplierContext } from '../SuppliersManagement';
import { ResultValues } from 'nuvo-react';
import { Nuvo } from '../../Nuvo';
import Button from '../../../../../ui/button/Button';
import Icon from '../../../../../ui/icon/Icon';
import { ICON_SIZE_MAP } from '../../../../../ui/icon/utils/constants';

export const Buttons = () => {
  const flags = useFeatureFlags();
  const { t } = useTranslation();
  const user = useContext(UserContext);

  const { setShowAddPurchase, total, totalLoading, setTotal } = useSupplierContext();

  if (totalLoading) {
    return null;
  }

  const handleShowAddPurchaseModal = () => {
    setShowAddPurchase(true);
  };

  const foundOrganization = user?.organizations?.find(
    (elem) => elem.id === user.selectedOrganization
  );

  const isBlocked =
    total === undefined || (foundOrganization && total >= foundOrganization.limit_purchases);

  const onUpload = (results: ResultValues) => {
    setTotal((prev) => {
      if (prev === undefined) {
        return results.length;
      }

      return prev + results.length;
    });
  };

  const BTNComponent = isBlocked ? (
    <Button lookAndFeel='blocked' text={t('purchases.uploadFile')} size='small' />
  ) : (
    <Nuvo onUpload={onUpload} />
  );

  return (
    <>
      {flags?.purchasesUpload && BTNComponent}
      <Button
        lookAndFeel={isBlocked ? 'blocked' : flags?.purchasesUpload ? 'secondary' : 'primary'}
        text={t('purchases.addManual')}
        size='small'
        onClick={handleShowAddPurchaseModal}
        iconNode={!isBlocked && <Icon icon='add' color='gray' size={ICON_SIZE_MAP.MEDIUM} />}
      />
    </>
  );
};
