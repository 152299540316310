import { useTranslation } from 'react-i18next';
import { Column } from '../../../../../../types/table';
import Icon from '../../../../../ui/icon/Icon';
import { StatusTag } from '../../../../../ui/statusTag';
import { OLD_PURCHASES_CODE, STATUS_I18N_KEYS } from '../../constants';
import type { IPrimaryCodeFrontend } from '../../../../../../types/entities/primaryCode';
import { limitString } from '../../../../../../utils/limitString';
import descriptionsPrimaryCode from '../../../../../../constants/json/sic_description.json';
import TooltipWrapper from '../../../../../ui/tooltip/TooltipWrapper';
import { Status } from '../../../../../../types/utilsEnums/status';

export const useColumns = (
  setData: React.Dispatch<React.SetStateAction<IPrimaryCodeFrontend | null>>,
  data?: IPrimaryCodeFrontend[]
) => {
  const { t } = useTranslation('translation', { keyPrefix: 'codeManagement.primaryCode.columns' });
  const { t: tooltipT } = useTranslation('translation', {
    keyPrefix: 'codeManagement.primaryCode.tooltip'
  });

  const handleClick = (id?: string) => () => {
    if (!id) return;

    setData(data?.find((element) => element.id === id) ?? null);
  };

  const columns: Column[] = [
    {
      title: t('code'),
      dataIndex: 'code',
      key: 'code',
      width: 100
    },
    {
      title: t('type'),
      dataIndex: 'framework',
      key: 'framework',
      width: 100
    },
    {
      title: t('description'),
      dataIndex: 'code',
      key: 'description',
      width: 150,
      render: (code) => {
        if (!code) return '-';

        const description = descriptionsPrimaryCode[code as keyof typeof descriptionsPrimaryCode];

        return description ? limitString(description, 80) : '-';
      }
    },
    {
      title: t('dcycle-product'),
      dataIndex: ['dcycleProduct', 'name'],
      key: 'dcycleProduct',
      render: (dcycleProduct) => (dcycleProduct ? limitString(dcycleProduct, 80) : '-')
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (status) =>
        status && (
          <StatusTag
            status={status as Status}
            text={STATUS_I18N_KEYS[status as keyof typeof STATUS_I18N_KEYS]}
          />
        )
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      render: (id, row) => {
        if (row.code === OLD_PURCHASES_CODE) {
          return (
            <TooltipWrapper text={tooltipT('cannotEdit')}>
              <Icon icon='edit' color='disabled' />
            </TooltipWrapper>
          );
        }
        return <Icon icon='edit' color='gradient' className='pointer' onClick={handleClick(id)} />;
      }
    }
  ];

  return { columns };
};
