import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import FormText from '../../../../../../ui/formComponents2/formInputs/formText/FormText';
import {
  IPurchaseSupplierBackend,
  IPurchaseSupplierFrontend
} from '../../../../../../../types/purchaseSupplier';
import { useContext, useState } from 'react';
import useOnChangeValue from '../../../../../../../customHooks/useOnChangeValue';
import FormSelect from '../../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import {
  PURCHASE_FRAMEWORK_OPTIONS,
  mapToPurchaseFramework
} from '../../../../../../../utils/mapToPurchaseFramework';
import Button from '../../../../../../ui/button/Button';
import { getCountries, getCountryNameByCode } from '../../../../../../../constants/phonePrefixes';
import { UserContext } from '../../../../../../../context/userContext';
import { patchSupplier } from '../../../../../../../services/api/purchaseSuppliers';
import { supplierFormSchema } from '../../schema/supplierForm';
import { InputSize } from '../../../../../../../types/utilsEnums/input';

type Props = {
  data: IPurchaseSupplierFrontend;
  editElement: (data: IPurchaseSupplierBackend) => void;
  setData: React.Dispatch<React.SetStateAction<IPurchaseSupplierFrontend | null>>;
};

type FormDataType = {
  name: string;
  country: string;
  code: string;
  framework: string;
  errors: ErrorType[];
};

export const SuppliersManagementEditForm: React.FC<Props> = ({ data, editElement, setData }) => {
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'purchases.suppliersTable.modal'
  });

  const user = useContext(UserContext);

  const [formData, setFormData] = useState<FormDataType>({
    name: data.name,
    code: data.purchaseCode?.code ?? '',
    framework: data.purchaseCode?.framework ?? 'cnae_2009',
    country: data.country ?? 'ES',
    errors: []
  });

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const foundOrganization = user?.organizations?.find(
    (item) => item.id === user.selectedOrganization
  );

  const getError = (key: keyof Exclude<FormDataType, 'errors'>) => {
    return formData.errors.find((elem) => elem.error === key);
  };

  const handleSubmit = async () => {
    const parsedFormData = supplierFormSchema.safeParse(formData);

    if (!parsedFormData.success) {
      const error = parsedFormData.error;

      setFormData((prev) => ({
        ...prev,
        errors: prev.errors.concat({
          error: error.errors[0].path.join('.'),
          description: t(error?.errors[0]?.message) ?? ''
        })
      }));

      return;
    }
    const response = await patchSupplier({
      id: data.id,
      name: formData.name,
      country: formData.country,
      purchaseCode: {
        code: formData.code,
        framework: formData.framework,
        codeType: data.purchaseCode?.codeType ?? 'sector'
      }
    });

    if (!response) {
      throw new Error('Error updating supplier');
    }

    editElement(response);

    setData(null);
  };

  return (
    <div className={`grid-cols-2 gap-x-2 gap-y-8 ${styles['modal']}`}>
      <div className={`col-span-2 gap-y-2 flex-col`}>
        <h3 className='headline3-font'>{t('title')}</h3>
        <p className='subtitle3-font'>{t('subTitle')}</p>
      </div>
      <FormText
        label={t('name')}
        placeholder={t('placeholders.name')}
        icon='/images/icons/briefcase.svg'
        size={InputSize.MEDIUM}
        disabled
        value={formData.name}
        error={getError('name')}
      />
      <FormSelect
        icon={'/images/icons/pin.svg'}
        placeholder={t('placeholders.country')}
        label={t('country')}
        value={{
          id: formData.country,
          name: getCountryNameByCode(formData.country, i18n.resolvedLanguage)
        }}
        onChange={(value) => onChangeValue('country')(value.id)}
        error={getError('country')}
        options={getCountries(
          i18n.resolvedLanguage,
          foundOrganization ? foundOrganization.country : 'ES'
        )}
        disabled
      />
      <FormText
        label={t('code')}
        placeholder={t('placeholders.code')}
        icon='/images/icons/inputNumeric.svg'
        size={InputSize.MEDIUM}
        value={formData.code}
        onChange={onChangeValue('code')}
        error={getError('code')}
        disabled={Boolean(data.officialPurchaseSupplierId)}
      />

      <FormSelect
        label={t('framework')}
        icon='/images/icons/dropdownOptions.svg'
        size={InputSize.MEDIUM}
        placeholder={t('placeholders.framework')}
        options={PURCHASE_FRAMEWORK_OPTIONS}
        value={{
          id: formData?.framework,
          name: mapToPurchaseFramework(formData?.framework)
        }}
        onChange={(value) => onChangeValue('framework')(value?.id)}
        error={getError('framework')}
        disabled={Boolean(data.officialPurchaseSupplierId)}
      />

      <Button
        lookAndFeel='primary'
        text={t('save')}
        size='medium'
        className='col-span-2'
        style={{ marginTop: '8px' }}
        onClick={handleSubmit}
        disabled={Boolean(data.officialPurchaseSupplierId)}
      />
    </div>
  );
};
