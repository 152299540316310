import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import reactStringReplace from 'react-string-replace';
import { MAX_PAYLOAD_SIZE } from '../../../../../constants';
import useGetBase64 from '../../../../../customHooks/useGetBase64';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import { uploadFilePurchases } from '../../../../../services/api/purchases';
import { IPurchaseGeneralBackend } from '../../../../../types/purchases';
import { User } from '../../../../../types/entities/user';
import { PurchaseType } from '../../../../../types/entitiesEnums/purchases';

import Button from '../../../../ui/button/Button';
import FileItem from '../../../../ui/fileItem/FileItem';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import InputFileSingle from '../../../../ui/formComponents/inputFile/InputFileSingle';
import HowToExport from '../howToExport/HowToExport';
import './styles.scss';
import { Status } from '../../../../../types/utilsEnums/status';
type Props = {
  user: User;
  addPurchase?: (purchase: IPurchaseGeneralBackend) => void;
};

type FormData = {
  file: File | null;
  errors: ErrorType[];
};

const UploadPurchasesFile = ({ user, addPurchase }: Props) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<FormData>({
    file: null,
    errors: []
  });

  const [loadingButton, setLoadingButton] = useState(false);
  const [showHowToExport, setShowHowToExport] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const { fileBase64 } = useGetBase64(formData.file as File);

  const handleRemoveFile = () => {
    const newErrors = formData.errors.filter((error) => error.error !== 'file');
    setFormData((prev) => ({
      ...prev,
      file: null,
      errors: newErrors
    }));
  };

  const handleUploadFile = async () => {
    try {
      if (!formData.file) {
        setFormData((prev) => ({
          ...prev,
          errors: [
            {
              error: 'file',
              description: t('error.fileRequired')
            }
          ]
        }));
        return;
      }
      const fileSize = formData.file.size / (1024 * 1024);

      if (fileSize >= MAX_PAYLOAD_SIZE) {
        setFormData((prev) => ({
          ...prev,
          errors: [
            {
              error: 'file',
              description: t('error.maxPayloadSize')
            }
          ]
        }));
        return;
      }
      setLoadingButton(true);
      if (fileBase64) {
        const data = await uploadFilePurchases(
          fileBase64 as string,
          formData.file.name,
          user.selectedOrganization ?? ''
        );
        addPurchase?.({
          id: data.id,
          product_name: '',
          recycled: false,
          quantity: 0,
          country: '',
          purchase_date: new Date(),
          frequency: 'once',
          unit_id: data.unit_id,
          file_url: data.file_url,
          status: Status.PENDING,
          purchase_type: PurchaseType.SPEND_BASE
        });
      }
      setLoadingButton(false);
    } catch (error) {
      setLoadingButton(false);
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const handleShowHowToExport = () => {
    setShowHowToExport(true);
  };

  const handleHideHowToExport = () => {
    setShowHowToExport(false);
  };

  const downloadFile = () => {
    const url = '/files/purchases/purchases_template_Dcycle.csv';
    window.open(url, '_blank');
  };

  const renderTitle = () => {
    const text = t('purchases.uploadYourFile');
    const replacements = [
      {
        search: '{{templates}}',
        component: (
          <span className='highlight-text-color pointer' onClick={downloadFile}>
            {t('purchases.exampleTemplates')}
          </span>
        )
      },
      {
        search: '{{info}}',
        component: (
          <span className='highlight-text-color pointer' onClick={handleShowHowToExport}>
            {t('purchases.fromYourAccounting')}
          </span>
        )
      }
    ];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let replacedText: any = text;

    replacements.forEach(({ search, component }) => {
      replacedText = reactStringReplace(replacedText, search, () => component);
    });

    return replacedText;
  };

  if (showHowToExport) {
    return <HowToExport goBack={handleHideHowToExport} />;
  }
  return (
    <div className='upload-purchases-file'>
      <FormHeader
        title={t('purchases.uploadFileTitle')}
        description={t('purchases.uploadFileDescription')}
      />
      <p className='file-label body1-bold-font'>{renderTitle()}</p>
      <InputFileSingle
        handleFile={onChangeValue('file')}
        buttonText={t('purchases.uploadFile')}
        labelText={t('purchases.dragAndDropFile')}
        fileName={formData.file?.name || ''}
        allowedTypes={[]}
        error={formData.errors.find((error) => error.error === 'file')}
      />
      {formData.file ? (
        <>
          <p className='file-label body1-bold-font'>{t('purchases.fileUploaded')}</p>
          <div className='files-wrapper'>
            <FileItem
              file={formData.file}
              index={0}
              icon={'/images/icons/paper.svg'}
              removeFile={handleRemoveFile}
            />
          </div>
        </>
      ) : null}
      <div className='buttons'>
        <Button
          lookAndFeel={'primary'}
          onClick={handleUploadFile}
          text={t('purchases.save')}
          loading={loadingButton}
        />
      </div>
    </div>
  );
};

export default UploadPurchasesFile;
