import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../../../customHooks/useOnChangeValue';
import { patchSecondaryCode } from '../../../../../../services/api/secondaryCode';
import { ISecondaryCodeBackend, ISecondaryCodeFront } from '../../../../../../types/secondaryCode';

import Button from '../../../../../ui/button/Button';
import { createDataContext } from '../../../../../ui/contexts/DataContext';
import FormText from '../../../../../ui/formComponents2/formInputs/formText/FormText';
import ModalDS from '../../../../../ui/modal/Modal';
import { codeSchema } from '../schema';
import { Status } from '../../../../../../types/utilsEnums/status';

const [ModalProvider, useModalContext] = createDataContext<ISecondaryCodeFront>();

type Props = {
  editElement: (element: ISecondaryCodeBackend) => void;
};

type FormData = {
  code?: string;
  errors: ErrorType[];
};

const Modal = ({ editElement }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'codeManagement.secondaryCode.modal' });

  const { data, setData } = useModalContext();

  const [formData, setFormData] = useState<FormData>({ code: data?.primaryCode, errors: [] });

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleClose = () => {
    setData(null);
  };

  const hasErrors = () => {
    const code = codeSchema.safeParse(formData.code);

    if (!code.success) {
      const { error } = code;

      const newErrors = [
        ...formData.errors,
        { error: 'code', description: t(error.errors[0].code) }
      ];

      setFormData({ ...formData, errors: newErrors });
      return true;
    }

    return false;
  };

  const handleReject = async () => {
    if (!data?.id) return;
    try {
      const response = await patchSecondaryCode(data?.id, { status: Status.ERROR });

      if (!response) {
        throw new Error('Something went wrong, please try again later.');
      }

      editElement(response);

      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleAccept = async () => {
    if (!data?.id || hasErrors()) return;

    try {
      const response = await patchSecondaryCode(data?.id, {
        primaryCode: formData.code,
        code: data?.code,
        framework: data?.framework
      });

      if (!response) {
        throw new Error('Something went wrong, please try again later.');
      }

      editElement(response);

      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setFormData({ code: data?.primaryCode, errors: [] });
  }, [data]);

  return (
    <ModalDS show={Boolean(data)} onClose={handleClose}>
      <>
        <h3 className='headline3-font'>{t('title')}</h3>
        <p className='subtitle3-font'>{t('subTitle')}</p>
        <div className='grid-cols-3 gap-x-4 gap-y-8'>
          <div className='col-span-2'>
            <FormText label={t('secondaryCode')} disabled value={data?.code} />
          </div>
          <FormText label={t('type')} disabled value={data?.framework} />
          <div className='col-span-3'>
            <FormText
              label={t('primaryCode')}
              onChange={onChangeValue('code')}
              value={formData.code}
              error={formData.errors.find(({ error }) => error === 'code')}
            />
          </div>
          <div className='flex col-span-3 gap-x-3 justify-end'>
            <Button
              size='medium'
              lookAndFeel='secondary'
              onClick={handleReject}
              disabled={data?.status === Status.ERROR}>
              {t('reject')}
            </Button>
            <Button
              size='medium'
              lookAndFeel='primary'
              onClick={handleAccept}
              disabled={Boolean(formData.errors.length)}>
              {t('accept')}
            </Button>
          </div>
        </div>
      </>
    </ModalDS>
  );
};

Modal.Provider = ModalProvider;

export { Modal, useModalContext };
