import { Status } from '../../../../types/utilsEnums/status';

export const PURCHASE_STATUS = {
  [Status.ACTIVE]: Status.ACTIVE,
  [Status.IN_PROGRESS]: Status.IN_PROGRESS,
  [Status.ERROR]: Status.ERROR,
  [Status.PENDING]: Status.PENDING
} as const;

export const AMZ_BUSINESS_ID = 'amazon_business';
