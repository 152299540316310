import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { months } from '../../../../constants/months';
import { scope3_color } from '../../../../styles/colors';
import { Organization } from '../../../../types/entities/organization';
import { PurchasesReportGHG } from '../../../../types/purchases';
import { formatNumberToDecimalNonZero } from '../../../../utils/numberToDecimal';
import LineChart2 from '../../../ui/charts/LineChart2';
import DescriptionBox from '../common/descriptionBox/DescriptionBox';
import LineChartWrapper from '../common/lineChartWrapper/LineChartWrapper';
import List from '../common/list/List';
import useDataCo2 from '../hooks/useDataCo2';

type Props = {
  data: PurchasesReportGHG;
  startDate: string;
  endDate: string;
  organizationCo2e: number;
  organization: Organization;
};
const Purchases = ({ data, startDate, endDate, organizationCo2e, organization }: Props) => {
  const { t, i18n } = useTranslation();

  const { dataCo2, monthLabels } = useDataCo2({ startDate, endDate });
  const dataQuantity = dataCo2.map((elem) => ({ x: elem.x, y: 0 }));
  // Group data by date
  const groupedByMonth = [] as GroupByMonthWithQuantity[];
  let totalCo2 = 0;

  data.items.forEach((elem) => {
    const elemDate = moment(new Date(elem.year, elem.month - 1)).format('YYYY-MM');

    totalCo2 += elem.co2e;
    groupedByMonth.push({
      date: elemDate,
      emissions_per_month: elem.co2e,
      quantity: elem.quantity
    });
  });

  groupedByMonth.forEach((elem) => {
    const foundIndex = dataCo2.findIndex((elem2) => elem2.x === elem.date);
    if (foundIndex > -1) {
      dataCo2[foundIndex].y += elem.emissions_per_month / 1000;
      dataQuantity[foundIndex].y += elem.quantity;
    }
  });

  const columns = [t('ghgReport.month'), t('ghgReport.co2eq.(tns)')];

  const rows: string[][] = [];

  dataCo2.forEach((elem) => {
    const dateString = elem.x;
    const date = new Date(dateString);
    const month = months.find((elem) => elem.number === date.getMonth() + 1);
    const lang = i18n.resolvedLanguage;

    rows.push([
      month?.name[lang],
      // numberToDecimal(dataQuantity[index].y).toString(),
      formatNumberToDecimalNonZero(elem.y)
    ]);
  });

  return (
    <div className='ghg-purchases'>
      <LineChartWrapper
        legends={[
          {
            name: 't CO₂ eq.',
            icon: '/images/icons/dotScope3.svg'
          }
        ]}>
        <LineChart2
          labels={monthLabels}
          chartData={[
            {
              label: 'scope3',
              borderColor: scope3_color,
              data: dataCo2.map((elem) => elem.y),
              borderWidth: 1,
              pointRadius: 0,
              pointHoverRadius: 0,
              stepped: false,
              backgroundColor: 'rgba(41, 69, 255, 0.1)',
              fill: true,
              tension: 0
            }
          ]}
          disabled={false}
        />
      </LineChartWrapper>
      <div className='list-description-wrapper'>
        <List
          icon={'/images/icons/calendar50.svg'}
          columns={columns}
          rows={rows}
          totalRow={[formatNumberToDecimalNonZero(totalCo2 / 1000)]}
        />
        <DescriptionBox
          descriptions={[
            t('ghgReport.purchasesEmissionsDescription.total', {
              total: formatNumberToDecimalNonZero(totalCo2 / 1000),

              percentage: formatNumberToDecimalNonZero((totalCo2 / organizationCo2e) * 100),
              tradeName: organization.trade_name ?? ''
            }),
            t('ghgReport.purchasesEmissionsDescription.recycled', {
              percentage: formatNumberToDecimalNonZero((data.total_recycled * 100) / data.total)
            })
          ]}
        />
      </div>
    </div>
  );
};

export default Purchases;
