import { useTranslation } from 'react-i18next';
import { IPurchaseSupplierFrontend } from '../../../../../../../types/purchaseSupplier';
import { Column } from '../../../../../../../types/table';
import { StatusTag } from '../../../../../../ui/statusTag';
import { limitString } from '../../../../../../../utils/limitString';
import { Status } from '../../../../../../../types/utilsEnums/status';

export const useColumns = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'purchases.suppliersTable'
  });
  const columns: Column<IPurchaseSupplierFrontend>[] = [
    {
      key: 'name',
      dataIndex: 'name',
      title: t('creditModal.columns.name'),
      render: (name) => limitString(name, 40),
      width: 250
    },
    {
      key: 'country',
      dataIndex: 'country',
      title: t('creditModal.columns.country'),
      width: 250
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: t('creditModal.columns.status'),
      render: (status: Status) => <StatusTag status={status} text={t(`tags.${status}`)} />,
      width: 150
    }
  ];

  return { columns };
};
