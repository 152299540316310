import { useContext, useEffect, useRef } from 'react';
import { TutorialContext } from '../components/layout/interactiveTutorial/mainTutorial/InteractiveTutorial';

const useOutsideClick = (callback: () => void) => {
  const tutorial = useContext(TutorialContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref: any = useRef();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClick = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (!tutorial?.active) callback();
      }
    };

    document.addEventListener('dblclick', handleClick, true);

    return () => {
      document.removeEventListener('dblclick', handleClick, true);
    };
  }, [ref, tutorial?.active]);

  return ref;
};

export default useOutsideClick;
